// NOTE(aes): https://stackoverflow.com/a/75871291

import { ofetch } from "ofetch"
import { forceLogout } from "~/assets/js/utilities"

export default defineNuxtPlugin((_nuxtApp) => {
        globalThis.$fetch = ofetch.create({
                onResponseError({ response }) {
                        if (response.status == 401) {
                                forceLogout(useRoute())
                        }
                },
        })
})
