import { z } from "zod"
import { empty, pick } from "@schtappe/utils"

import * as Definitions from "./definitions.js"
import * as Shared from "./shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, required: true, form: false },

        account_id: { type: Integer },
        expiration_date: { type: Date },
        reference_no: { type: String },
        adjustment: { type: Decimal },

        sms_credits: { type: Integer, form: false },
        call_credits: { type: Integer, form: false },
        consumed_sms_credits: { type: Integer, form: false },
        consumed_call_credits: { type: Integer, form: false },
        price: { type: Decimal, form: false },
        plan_amount: { type: Decimal, form: false },
        plan_name: { type: String, form: false },
        status: { type: Integer, form: false },
        invoice_no: { type: String, form: false },
        billing_statement: { type: String, nullable: true, form: false },
        created_at: { type: Date, form: false },
        updated_at: { type: Date, form: false },
        sms_rate: { type: Decimal, form: false },
        call_rate: { type: Decimal, form: false },
        credit_limit: { type: Decimal, form: false },
        base_call_usage_duration: { type: Integer, form: false },
        consumed_extended_globe_call_credits: { type: Integer, form: false },
        consumed_extended_cross_call_credits: { type: Integer, form: false },
        cross_call_rate: { type: Decimal, form: false },
        nominated_caller_id_rate: { type: Decimal, form: false },
        nominated_caller_id_count: { type: Integer, form: false },
}

const filters = [
        { key: "account", group: "select_model", option_key: "accounts" },
        { key: "sms_credits", group: "number" },
        { key: "expiration_date", group: "date" },
        { key: "call_credits", group: "number" },
        { key: "consumed_sms_credits", group: "number" },
        { key: "consumed_call_credits", group: "number" },
        { key: "price", group: "number" },
        { key: "plan_amount", group: "number" },
        { key: "plan_name", group: "text" },
        { key: "status", group: "number" },
        { key: "reference_no", group: "text" },
        { key: "invoice_no", group: "text" },
        { key: "billing_statement", group: "text" },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
        { key: "sms_rate", group: "number" },
        { key: "call_rate", group: "number" },
        { key: "credit_limit", group: "number" },
        { key: "base_call_usage_duration", group: "number" },
        { key: "consumed_extended_globe_call_credits", group: "number" },
        { key: "consumed_extended_cross_call_credits", group: "number" },
        { key: "cross_call_rate", group: "number" },
        { key: "nominated_caller_id_rate", group: "number" },
        { key: "adjustment", group: "number" },
]

export const Request = ({ helpers, config }) => ({
        List: {
                Query: (data) => z.object({
                        ...Shared.Request.Paginated(),
                        q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                                .optional()
                                .default({}),
                })
                        .transform(({ q, ...data }) => ({
                                ...data,
                                ...helpers.toQueryObject({ query: q, filters: config.filters }),
                        }))
                        .safeParse(data),
        },
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return {
                                [config.key]: {
                                        account_id: 1,
                                        expiration_date: new Date(),
                                        referenze_no: "",
                                        adjustment: 0,
                                }
                        }
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form })
                        .safeParse(data)
        },
})

export const Response = ({ helpers, config }) => ({
        FilterOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        FormOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        adjustment: z.coerce.number(),
                        account: z.string(),
                        billing_period: z.string(),
                        plan_id: z.number(),
                        plan_name: z.string(),
                        base: z.coerce.number(),
                        extended_calls: z.coerce.number(),
                        extended_sms: z.coerce.number(),
                        glabs_caller_id: z.coerce.number(),
                        total_monthly_charges: z.coerce.number(),
                        download: z.string(),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({ attributes }),
                account: z.string(),
        }).safeParse,
})

const Form = ({ helpers, config }) => ({
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: ([attribute, value]) => {
                        const result = {}
                        switch (attribute) {
                                case "account_id": {
                                        result.title = "Account"
                                        result.options = []
                                        result.option_key = "accounts"
                                } break
                                case "expiration_date": {
                                        result.disabled = true
                                } break
                        }
                        return result
                },
        }),
})

export const Api = ({ helpers, config }) => ({
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List.Query,
                successParser: config.Response.List,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form
                }),
                errorParser: Shared.Response.Errors,
        },
})

export const Scopes = () => empty(Array)

export const TableHeaders = () => [
        { title: "Id", key: "id" },
        { title: "Account", key: "account" },
        // { title: "Reference No", key: "reference_no" }, // TODO(aes): why is this here?
        { title: "Billing Period", key: "billing_period" },
        { title: "Plan", key: "plan_name" },
        { title: "Base", key: "base" },
        { title: "Extended Calls", key: "extended_calls" },
        { title: "Extended SMS", key: "extended_sms" },
        { title: "Glabs CallerId", key: "glabs_caller_id" },
        { title: "Adjustment", key: "adjustment" },
        { title: "Total Monthly Charges", key: "total_monthly_charges" },
]

export const Pages = () => ({
        list: {
                breadcrumb: { actions: [] },
                actions: [{ name: "Download", link: ({ baseUrl, item }) => (new URL(item.download, baseUrl)).href }],
        },
        show: {
                title: ({ resource }) => `Billing #${resource.id}`,
                actions: [
                        { name: "Edit Billing", path: "edit" },
                        { name: "Download", api: true, path: "download", target: "_blank" },
                ],
                groups: [
                        {
                                name: "Billing Details",
                                type: "property",
                                value: (resource) => {
                                        const result = pick([
                                                "sms_credits",
                                                "expiration_date",
                                                "call_credits",
                                                "consumed_sms_credits",
                                                "consumed_call_credits",
                                                "price",
                                                "plan_amount",
                                                "plan_name",
                                                "account",
                                                "status",
                                                "reference_no",
                                                "invoice_no",
                                                "billing_statement",
                                                "created_at",
                                                "updated_at",
                                                "sms_rate",
                                                "call_rate",
                                                "credit_limit",
                                                "base_call_usage_duration",
                                                "consumed_extended_globe_call_credits",
                                                "consumed_extended_cross_call_credits",
                                                "cross_call_rate",
                                                "nominated_caller_id_rate",
                                                "nominated_caller_id_count",
                                                "adjustment",
                                        ], resource)

                                        result.account = { link: `/admin/accounts/${resource.account_id}`, value: result.account }

                                        return result
                                },
                        },
                ],
        },
})

export default Shared.defineAdminModel({
        key: "billing",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        Scopes,
        TableHeaders,
        Pages,
})
