// TODO(aes): find a way to elegantly import these dependencies

import $ from "jquery"
window.$ = window.jquery = window.jQuery = $
import "jquery.panzoom"

// NOTE(aes): https://github.com/vitejs/vite/discussions/9415#discussioncomment-4081977
import("jquery-ui/dist/jquery-ui")

import("print-this")

import moment from "moment"
window.moment = moment
import("daterangepicker")

import "bootstrap"

import { forceLogout } from "~/assets/js/utilities"

// NOTE(aes): defineNuxtPlugin() is needed, or else this file will not execute!
export default defineNuxtPlugin((nuxtApp) => {
        if (typeof window !== 'undefined') {
                // NOTE(aes): setup all $.ajax with correct baseURL
                //            https://stackoverflow.com/a/30766123
                $.ajaxSetup({
                        beforeSend: function(_xhr, options) {
                                if (!(options?.url && typeof options.url === "string")) return

                                if (options.url.startsWith("/"))
                                        options.url = useRuntimeConfig().public.baseUrl + options.url
                                else
                                        options.url = options.url
                        },
                        xhrFields: {
                                withCredentials: true
                        },
                        statusCode: {
                                401: function() {
                                        forceLogout()
                                }
                        }
                })
        }
})
