import axios from "axios"
import { forceLogout } from "~/assets/js/utilities"

export default defineNuxtPlugin((nuxtApp) => {
        const http = axios.create({
                baseURL: useRuntimeConfig().public.baseUrl,

                // NOTE(aes): only allow application/json;
                //            if */* is included, rails with respond with html
                headers: { Accept: 'application/json' },

                withCredentials: true,
        })

        http.interceptors.request.use(
                function(config) {
                        const csrfToken = useCookie("CSRF-TOKEN").value
                        if (csrfToken) {
                                config.headers["X-CSRF-TOKEN"] = csrfToken
                        }
                        return config
                },
                function(error) {
                        // TODO(aes)
                        console.error(error)
                        return Promise.reject(error)
                })

        const success = (response) => response
        const failure = (error) => {
                if (error.response.status == 401) {
                        forceLogout()
                }
                return Promise.reject(error)
        }
        http.interceptors.response.use(success, failure);

        if (typeof window !== 'undefined') {
                window.axios = http
        }

        return {
                provide: { http }
        }
})
