const KEY = "ives.user"

const user = ref(null)
try {
        user.value = JSON.parse(localStorage.getItem(KEY))
} catch (error) {
        // TODO(aes): logging
        console.error(error)
}

export const useCredentials = () => {
        const clear = () => {
                localStorage.removeItem(KEY)
                user.value = null
        }

        const update = (value) => {
                if (!value) return

                try {
                        localStorage.setItem(KEY, JSON.stringify(value))
                        user.value = value
                } catch (error) {
                        // TODO(aes): logging
                        console.error(error)
                }
        }

        // NOTE(aes): /admin/login does not provide role_name.
        // since this is needed by middleware, add it manually
        const updateAsAdmin = (value) => {
                update({
                        ...value,
                        role_name: "admin",
                })
        }

        const firstLetter = () => {
                const value = user.value
                return (value && value.first_name)
                        ? value.first_name[0]
                        : "A"
        }

        const fullName = () => {
                const value = user.value
                return (value && value.first_name && value.last_name)
                        ? `${value.first_name} ${value.last_name}`
                        : ""
        }

        const userId = () => {
                const value = user.value
                return (value && value.id)
                        ? value.id
                        : ""
        }

        const isDmsEnabled = () => {
                const value = user.value
                return !!(value && value.user_is_dms_enabled)
        }

        const can = (permission, module) => {
                const value = user.value
                if (!value) return false
                const rights = value.access_rights
                if (!rights) return false
                return rights.some((r) => r.module == module && r.permission == permission)
        }

        const isAdmin = () => {
                const value = user.value
                return !!(value && (value.role_name == "admin"))
        }

        return {
                user,
                update,
                updateAsAdmin,
                clear,
                firstLetter,
                fullName,
                userId,
                isDmsEnabled,
                can,
                isAdmin,
        }
}
