import { z } from "zod"
import { empty, pick, omit } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, form: false },
        sms_credits: { type: Integer, disabled: true },
        call_credits: { type: Decimal, disabled: true },
        price: { type: Decimal },
        credit_limit: { type: Decimal },
        base_call_usage_duration: { type: Integer },
        cross_call_rate: { type: Decimal },
        call_rate: { type: Decimal },
        nominated_caller_id_rate: { type: Decimal },
}

const Request = ({ helpers, config }) => ({
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form }).safeParse(data)
        },
})

const Response = ({ helpers, config }) => ({
        Show: z.object({
                ...helpers.modelToParser({
                        attributes: config.attributes,
                }),
                account_id: z.number(),
        }).safeParse,
})

const Form = ({ helpers, config }) => ({
        Update: helpers.generateFormFields({
                initial: [{ type: "group", title: "Account Wallet" }],
                attributes: config.attributes,
        }),
})

const Api = ({ helpers, config }) => ({
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
})

const TableHeaders = [
]

const Pages = {
}

export default Shared.defineAdminModel({
        key: "wallet",
        attributes,
        Request,
        Response,
        Form,
        Api,
        TableHeaders,
        Pages,
})
