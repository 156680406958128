import { z } from "zod"

export const Request = {
        ReportsGenerate: z.object({
                admin_reports_form: z.object({
                        category: z.string(),
                        report: z.string(),
                }),
                options: z.object({
                        client_group: z.string(),
                        year: z.string(),
                }),
        }).safeParse,
}

export const Response = {
        Dashboard: z.object({
                caller_id_requests: z.object({
                        id: z.number(),
                        caller_id: z.string(),
                        account: z.object({
                                id: z.number(),
                                company: z.string(),
                                primary_user: z.object({
                                        first_name: z.string(),
                                        last_name: z.string(),
                                }).optional(),
                        }),
                }).array(),
                plan_requests: z.object({
                        id: z.number(),
                        name: z.string().nullable(), // TODO(aes): not sure why this is null / can't be set
                        account: z.object({
                                id: z.number(),
                                company: z.string(),
                                primary_user: z.object({
                                        first_name: z.string(),
                                        last_name: z.string(),
                                }).optional(),
                        }),
                }).array(),
                sms_masking_requests: z.object({
                        id: z.number(),
                        name: z.string(),
                        account: z.object({
                                id: z.number(),
                                company: z.string(),
                                primary_user: z.object({
                                        first_name: z.string(),
                                        last_name: z.string(),
                                }).optional(),
                        }),
                }).array(),
                upcoming_campaigns: z.object({
                        id: z.number(),
                        name: z.string(),
                        rows: z.number(),
                        blast_at: z.string(),
                        variables: z.string(),
                        caller_id: z.string(),
                        non_globe_caller_id: z.string(),
                        sms_masking: z.string(),
                        account: z.object({
                                id: z.number(),
                                company: z.string(),
                                primary_user: z.object({
                                        first_name: z.string(),
                                        last_name: z.string(),
                                }).optional(),
                        }),
                }).array(),
                ongoing_campaigns: z.object({
                        id: z.number(),
                        name: z.string(),
                        rows: z.number(),

                        ringing: z.number(),
                        answered: z.number(),
                        unanswered: z.number(),
                        busy: z.number(),
                        unreachable: z.number(),
                        failed: z.number(),
                        overdue: z.number(),

                        blast_at: z.string(),
                        account: z.object({
                                id: z.number(),
                                company: z.string(),
                                primary_user: z.object({
                                        first_name: z.string(),
                                        last_name: z.string(),
                                })
                        }),
                }).array(),
        }).safeParse,
        ReportsFormOptions: z.object({
                report_category_select_options: z.record(z.string(), z.number()),
                bills_and_payments_report_select_options: z.record(z.string(), z.number()),
                account_client_group_select_options: z.record(z.string(), z.number().or(z.string())),
        }).safeParse,
        ReportsGenerate: z.string().safeParse,
}

export const Form = {}

export const Api = {
        Dashboard: {
                successParser: Response.Dashboard,
        },
}
