import { z } from "zod"
import { empty, pick } from "@schtappe/utils"

import { formatters, isUndecided } from "../helpers/admin.js"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { transformVNodeArgs } from "vue"

const { Integer, Decimal } = Definitions

// TODO(aes): how to get this dynamically from backend
//  account_id                    :bigint(8)
//  base_call_usage_duration      :integer          default(30)
//  call_credits                  :integer          default(0)
//  call_rate                     :decimal(, )      default(0.0)
//  campaign_record_limit_per_day :integer          default(0)
//  created_at                    :datetime         not null
//  cross_call_rate               :decimal(, )      default(0.0)
//  description                   :text
//  expiration_days               :integer          default(0)
//  id                            :bigint(8)        not null, primary key
//  ivr_tree_cnt                  :integer          default(0)
//  name                          :string
//  nominated_caller_id_rate      :decimal(, )      default(0.0), not null
//  plan_ref_id                   :integer
//  price                         :decimal(16, 2)   default(0.0)
//  sms_credits                   :integer          default(0)
//  sms_rate                      :decimal(, )      default(0.0)
//  status                        :integer          default(0)
//  updated_at                    :datetime         not null

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        account_id: { type: Integer, required: true, default: 1 },
        name: { type: String, nullable: true },
        price: { type: Decimal }, // TODO(aes): if float, string?
        sms_credits: { type: Integer },
        call_credits: { type: Integer },
        status: { type: Integer },
        sms_rate: { type: Decimal },
        call_rate: { type: Decimal },
        cross_call_rate: { type: Decimal },
        base_call_usage_duration: { type: Integer },
        nominated_caller_id_rate: { type: Decimal },
        created_at: { type: Date, form: false },
}

const filters = [
        { key: "account", group: "select_model", option_key: "accounts" },
        { key: "users", group: "select_model", option_key: "users" },
        { key: "name", group: "text" },
        { key: "sms_credits", group: "number" },
        { key: "call_credits", group: "number" },
        { key: "status", group: "select_fixed", option_key: "statuses", option_type: Number },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
]

export const Request = ({ helpers, config }) => ({
        Create: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                return z.object({
                        [config.key]: z.object(helpers.modelToParser({
                                exclude: ["id", "created_at"],
                                attributes,
                        }))
                }).safeParse(data)
        },
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                ...Shared.Request.Scoped(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                return z.object({
                        [config.key]: z.object(helpers.modelToParser({
                                exclude: ["id", "created_at"],
                                attributes,
                        }))
                }).safeParse(data)
        },
})

export const Response = ({ helpers, config }) => ({
        FormOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        Create: z.object({
                id: z.number(),
        }).safeParse,
        FilterOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                users: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        name: z.string().nullable(),
                        account: z.string(),
                        price: z.coerce.number(),
                        sms_credits: z.number(),
                        call_credits: z.number(),
                        sms_rate: z.coerce.number(),
                        call_rate: z.coerce.number(),
                        cross_call_rate: z.coerce.number(),
                        base_call_usage_duration: z.number(),
                        nominated_caller_id_rate: z.coerce.number(),
                        status: z.string(),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Count: z.object({
                for_approval: z.number(),
                approved: z.number(),
                disapproved: z.number(),
                cancelled: z.number(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({ attributes }),
                account: z.string(),
                status_name: z.string(),
                description: z.string(),
        }).safeParse,
})

const transformer = ([attribute, value]) => {
        const result = {}
        switch (attribute) {
                case "account_id": {
                        result.title = "Account"
                        result.options = []
                        result.option_key = "accounts"
                } break
                case "name": {
                        result.disabled = true
                } break
                case "status": {
                        result.options = []
                        result.option_key = "statuses"
                } break
        }
        return result
}

export const Form = ({ helpers, config }) => ({
        Create: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
})

export const Api = ({ helpers, config }) => ({
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        Create: {
                bodyParser: (options = { form: {} }) => config.Request.Create({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
                successParser: config.Response.Create,
        },
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Count: {
                successParser: config.Response.Count,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form
                }),
                errorParser: Shared.Response.Errors,
        },
        Approve: empty(Object),
        Disapprove: empty(Object),
})

// TODO(aes): make dynamic based on server
export const Scopes = () => Shared.Scopes([
        "for_approval",
        "approved",
        "disapproved",
        "cancelled",
])

export const TableHeaders = () => Shared.TableHeaders([
        "id",
        "name",
        "account",
        "price",
        "sms_credits",
        "call_credits",
        "sms_rate",
        "call_rate",
        "cross_call_rate",
        "base_call_usage_duration",
        "nominated_caller_id_rate",
        "status",
])

export const Pages = ({ helpers, config }) => ({
        list: {
                actions: [
                        {
                                name: "Approve",
                                handle: "approve",
                                enabled: ({ status: status_name }) => isUndecided(status_name),
                        },
                        {
                                name: "Disapprove",
                                handle: "disapprove",
                                enabled: ({ status: status_name }) => isUndecided(status_name),
                        },
                ]
        },
        show: {
                title: ({ resource }) => `Account Requested Plan #${resource.id}`,
                actions: [
                        { name: "Edit Account Requested Plan", path: "edit" },
                        {
                                name: "Approve",
                                operation: {
                                        name: "approve",
                                        enabled: ({ status_name }) => isUndecided(status_name)
                                },
                        },
                        {
                                name: "Disapprove",
                                operation: {
                                        name: "disapprove",
                                        enabled: ({ status_name }) => isUndecided(status_name)
                                },
                        },
                ],
                groups: [
                        {
                                name: "Account Requested Plan Details",
                                type: "property",
                                value: (resource) => {
                                        const { name, account, status_name, description, created_at } = resource
                                        const result = {
                                                name,
                                                account: { link: `/admin/accounts/${resource.account_id}`, value: account },
                                                status: status_name,
                                                description: { html: description },
                                                created_at: formatters.date(created_at)
                                        }
                                        return result
                                },
                        },
                ],
        }
})

export default Shared.defineAdminModel({
        key: "account_requested_plan",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        Scopes,
        TableHeaders,
        Pages,
})
