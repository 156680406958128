/**
 * source: app/assets/javascripts/plugins/report.js
 */

export default defineNuxtPlugin((nuxtApp) => {
        const instance = nuxtApp.$http

        function CampaignListTranslatorFactory() {
                function toListFromRepresentation(listInRepresentation, listClass) {
                        const campaignList = new listClass(
                                listInRepresentation.data,
                                listInRepresentation.total_items,
                                listInRepresentation.per_page,
                                listInRepresentation.from,
                                listInRepresentation.to,
                                listInRepresentation.current_page,
                                listInRepresentation.page_total
                        )
                        return campaignList
                }

                this.toListFromRepresentation = toListFromRepresentation
        }
        const CampaignListTranslator = new CampaignListTranslatorFactory()

        function CampaignListAdaptorFactory(httpInstance) {
                async function toList(page, search, campaignListClass) {
                        let campaignList = null
                        try {
                                const response = await httpInstance.get('/campaign_files.json', {
                                        params: {
                                                page,
                                                sorted_by: 'created_at_desc',
                                                with_filename: search,
                                                per: 5
                                        }
                                })
                                campaignList = CampaignListTranslator.toListFromRepresentation(response.data, campaignListClass)
                        } catch (error) {
                                // TODO: Handle error
                                console.error(error)
                        }
                        return campaignList
                }

                this.toList = toList
        }
        const CampaignListAdaptor = new CampaignListAdaptorFactory(instance)

        function KillListTranslatorFactory() {
                function toListFromRepresentation(listInRepresentation, listClass) {
                        const killList = new listClass(
                                listInRepresentation.data,
                                listInRepresentation.total_items,
                                listInRepresentation.per_page,
                                listInRepresentation.from,
                                listInRepresentation.to,
                                listInRepresentation.current_page,
                                listInRepresentation.page_total
                        )
                        return killList
                }

                this.toListFromRepresentation = toListFromRepresentation
        }
        const KillListTranslator = new KillListTranslatorFactory()

        function KillListAdaptorFactory(httpInstance) {
                async function toList(page, search, killListClass) {
                        let killList = null
                        try {
                                const response = await httpInstance.get('/kill_update_files.json', {
                                        params: {
                                                page,
                                                sorted_by: 'created_at_desc',
                                                with_filename: search,
                                                per: 5
                                        }
                                })
                                killList = KillListTranslator.toListFromRepresentation(response.data, killListClass)
                        } catch (error) {
                                // TODO: Handle error
                                console.error(error)
                        }
                        return killList
                }

                this.toList = toList
        }
        const KillListAdaptor = new KillListAdaptorFactory(instance)

        function FeedbackListTranslatorFactory() {
                function toListFromRepresentation(listInRepresentation, listClass) {
                        const feedbackList = new listClass(
                                listInRepresentation.data,
                                listInRepresentation.total_items,
                                listInRepresentation.per_page,
                                listInRepresentation.from,
                                listInRepresentation.to,
                                listInRepresentation.current_page,
                                listInRepresentation.page_total
                        )
                        return feedbackList
                }

                this.toListFromRepresentation = toListFromRepresentation
        }
        const FeedbackListTranslator = new FeedbackListTranslatorFactory()

        function FeedbackListAdaptorFactory(httpInstance) {
                async function toList(page, search, feedbackListClass) {
                        let feedbackList = null
                        try {
                                const response = await httpInstance.get('/ivr_feedbacks.json', {
                                        params: {
                                                page,
                                                sorted_by: 'created_at_desc',
                                                with_filename: search,
                                                per: 5
                                        }
                                })
                                feedbackList = FeedbackListTranslator.toListFromRepresentation(response.data, feedbackListClass)
                        } catch (error) {
                                // TODO: Handle error
                                console.error(error)
                        }
                        return feedbackList
                }

                this.toList = toList
        }
        const FeedbackListAdaptor = new FeedbackListAdaptorFactory(instance)

        function FileModuleService() {
                this.campaignList = async function campaignList(page, search, campaignListClass) {
                        const campaignList = CampaignListAdaptor.toList(page, search, campaignListClass)
                        return campaignList
                }

                this.campaignDownloadLink = function campaignDownloadLink(campaignId) {
                        return `/campaign_files/${campaignId}/download_file`
                }

                this.killList = async function killList(page, search, killListClass) {
                        const killList = KillListAdaptor.toList(page, search, killListClass)
                        return killList
                }

                this.killUpdateFileDownloadLink = function killUpdateFileDownloadLink(killUpdateFileId) {
                        return `/kill_update_files/${killUpdateFileId}/download_file`
                }

                this.feedbackList = async function campaignList(page, search, feedbackListClass) {
                        const feedbackList = FeedbackListAdaptor.toList(page, search, feedbackListClass)
                        return feedbackList
                }

                this.feedbackItemDownloadLink = function feedbackItemDownloadLink(feedbackItemId) {
                        return `/ivr_feedbacks/${feedbackItemId}/download_file`
                }
        }

        const humanize = {
                timeDate(dateString) {
                        const date = moment(dateString)
                        return date.format('h:mm A - MMMM DD, YYYY')
                },
                capitalize(value) {
                        value = value.toString()
                        return value.charAt(0).toUpperCase() + value.slice(1)
                },
                addCommaBetweenSpaces(value) {
                        if (typeof value !== 'string') {
                                return value
                        }

                        return value.split(',').join(', ')
                },
                kebabCase(value) {
                        value = value.toString()
                        return value
                                .replace(/(\s|_)+/g, '-')
                                .toLowerCase()
                },
                pluralize(count, singular, plural) {
                        if (count <= 1) {
                                return `${count} ${singular}`
                        }
                        return `${count} ${plural}`
                },
        }

        return {
                provide: {
                        fileModuleService: new FileModuleService(),
                        humanize,
                }
        }
})
