// TODO(aes): refactor to own model
export const createAccountDropdown = (id) => ([
        {
                link: `/admin/accounts/${id}/account_requested_plans?scope=for_approval`,
                name: "Plans",
        },
        {
                link: `/admin/accounts/${id}/caller_ids?scope=for_approval`,
                name: "Caller IDs",
        },
        {
                link: `/admin/accounts/${id}/sms_maskings?scope=for_approval`,
                name: "SMS Maskings",
        },
])

// TODO(aes): this is for account requested plan only, refactor this!
// move this logic to backend; create a new field in json response
export const isUndecided = (status) => !(["approved", "disapproved", "cancelled"].includes(status))

export const addOptions = (fields, attribute, options) => {
        const field = fields.find((field) => field.attribute == attribute)
        if (!field) return
        field.options = options
}

export const formatters = {
        // TODO(aes): improve this?
        date: (new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
        })).format
}

export const getPaginationLinks = (options = {}) => {
        const total = options.page_total || 1
        if (total == 1) return []

        const DEPTH = 5
        const current = options.current_page || 1
        const result = []

        if (current > 1) {
                result.push(
                        { name: "First", value: 1 },
                        { name: "Previous", value: current - 1 },
                )

                for (let min = current - DEPTH; min < current; ++min) {
                        if (min < 1) continue
                        result.push({ name: min.toString(), value: min })
                }
        }

        result.push({ name: current.toString(), value: current, active: true })

        if (current < total) {
                for (let min = (current + 1), max = min + DEPTH; min < max; ++min) {
                        if (min > total) break
                        result.push({ name: min.toString(), value: min })
                }

                result.push(
                        { name: "Next", value: current + 1 },
                        { name: "Last", value: total },
                )
        }

        return result
}
