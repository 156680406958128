import { z } from "zod"
import { empty, pick, transformers } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        account_id: { type: Integer, required: true, default: 1 },
        name: { type: String, required: true },
        authorization_letter: { type: File, required: true },
        status: { type: Integer, nullable: true },
        remarks: { type: String, nullable: true },
        created_at: { type: Date, form: false, },
        updated_at: { type: Date, form: false },
}

const filters = [
        { key: "account", group: "select_model", option_key: "accounts" },
        { key: "name", group: "text" },
        { key: "status", group: "select_fixed", option_key: "statuses", option_type: Number },
        { key: "remarks", group: "text" },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
]

export const Request = ({ helpers, config }) => ({
        Create: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form })
                        .transform(transformers.object.toFormData)
                        .safeParse(data)
        },
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                ...Shared.Request.Scoped(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form })
                        .transform(transformers.object.toFormData)
                        .safeParse(data)
        },
        TestSms: z.object({
                recipient: z.string("mobile number: 09001231231"),
        }).safeParse,
})

export const Response = ({ helpers, config }) => ({
        FormOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        Create: z.object({
                id: z.number(),
        }).safeParse,
        FilterOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        name: z.string(),
                        account: z.string(),
                        authorization_letter: z.string(),
                        status: z.string().nullable(),
                        decided: z.boolean(),
                        ...Shared.Response.Timestamps(),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Count: z.object({
                for_approval: z.number(),
                cancelled: z.number(),
                approved: z.number(),
                disapproved: z.number(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({ exclude: ["account_id", "authorization_letter"], attributes }),
                status_name: z.string().nullable(),
                authorization_letter_url: z.string(),
                decided: z.boolean(),
        }).safeParse,
        // TODO(aes): what is the expected response?
        TestSms: z.any().safeParse,
})

const Errors = {
        TestSms: z.object({
                error: z.string(),
        }).safeParse,
}

const transformer = ([attribute, value]) => {
        const result = {}
        switch (attribute) {
                case "account_id": {
                        result.title = "Account"
                        result.options = []
                        result.option_key = "accounts"
                } break
                case "status": {
                        result.options = []
                        result.option_key = "statuses"
                } break
                case "remarks": {
                        result.type = "textarea"
                } break
        }
        return result
}

export const Form = ({ helpers, config }) => ({
        Create: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
})

export const Api = ({ helpers, config }) => ({
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        Create: {
                bodyParser: (options = { form: {} }) => config.Request.Create({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
                successParser: config.Response.Create,
        },
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Count: {
                successParser: config.Response.Count,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form
                }),
                errorParser: Shared.Response.Errors,
        },
        TestSms: {
                bodyParser: (options = { form: "" }) => config.Request.TestSms({
                        recipient: options.form,
                }),
                successParser: config.Response.TestSms,
                errorParser: config.Errors.TestSms,
        },
})

// TODO(aes): make dynamic based on server
export const Scopes = () => Shared.Scopes([
        "for_approval",
        "cancelled",
        "approved",
        "disapproved",
])

export const TableHeaders = () => Shared.TableHeaders([
        "id",
        "name",
        "account",
        {
                key: "authorization_letter",
                getLink: ({ baseUrl, item }) => (new URL(item.authorization_letter, baseUrl)).href,
                format: () => "Download",
        },
        "status",
        { key: "created_at", format: formatters.date }
])

export const Pages = ({ helpers, config }) => ({
        list: {
                actions: [
                        {
                                name: "Test Sms",
                                enabled: (resource) => resource.decided,
                                dialog: {
                                        name: "test_sms",
                                        title: "Please enter mobile number to receive the test sms with masking name",
                                        label: "Recipient",
                                        placeholder: "09XXXXXXXXX",
                                },
                        },
                ],
        },
        show: {
                actions: [
                        { name: "Edit Sms Masking", path: "edit" },
                        {
                                name: "Approve",
                                operation: {
                                        name: "approve",
                                        enabled: (resource) => !resource.decided,
                                },
                        },
                        {
                                name: "Disapprove",
                                operation: {
                                        name: "disapprove",
                                        enabled: (resource) => !resource.decided,
                                },
                        },
                        {
                                name: "Test Sms",
                                dialog: {
                                        name: "test_sms",
                                        enabled: (resource) => resource.decided,
                                        title: "Please enter mobile number to receive the test sms with masking name",
                                        label: "Recipient",
                                        placeholder: "09XXXXXXXXX",
                                },
                        },
                ],
                groups: [
                        {
                                name: "Sms Masking Details",
                                type: "property",
                                value: (resource) => {
                                        const result = {}

                                        result.name = resource.name
                                        result.status = resource.status_name
                                        result.authorization_letter = {
                                                api: true,
                                                link: resource.authorization_letter_url,
                                                value: "Download",
                                        }
                                        result.remarks = resource.remarks
                                        result.created_at = resource.created_at
                                        result.updated_at = resource.updated_at

                                        return result
                                },
                        },
                ],
        },
})

export default Shared.defineAdminModel({
        key: "sms_masking",
        attributes,
        filters,
        Request,
        Response,
        Errors,
        Form,
        Api,
        Scopes,
        TableHeaders,
        Pages,
})
