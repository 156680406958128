import { z } from "zod"
import { empty, pick, omit } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal, INPUT_MAP } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1 },
        first_name: { type: String },
        last_name: { type: String },
        mobile_number: { type: String },
        organization: { type: String },
        email_address: { type: String },
        content: { type: String },
        created_at: { type: Date },
        updated_at: { type: Date },
}

const filters = [
        { key: "first_name", group: "text" },
        { key: "last_name", group: "text" },
        { key: "mobile_number", group: "text" },
        { key: "organization", group: "text" },
        { key: "email_address", group: "text" },
        { key: "content", group: "text" },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
]

const Request = ({ helpers, config }) => ({
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
})

const Response = ({ helpers, config }) => ({
        List: z.object({
                data: z.object({
                        id: z.number(),
                        first_name: z.string(),
                        last_name: z.string(),
                        mobile_number: z.string(),
                        organization: z.string(),
                        email_address: z.string(),
                        content: z.string(),
                        created_at: z.coerce.date({ offset: true }),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
})

const Api = ({ helpers, config }) => ({
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
})

const TableHeaders = [
        { title: "Id", key: "id" },
        { title: "First Name", key: "first_name" },
        { title: "Last Name", key: "last_name" },
        { title: "Mobile Number", key: "mobile_number" },
        { title: "Organization", key: "organization" },
        { title: "Email Address", key: "email_address" },
        { title: "Content", key: "content" },
        { key: "created_at", format: formatters.date },
]

const Pages = {
        list: {
                breadcrumb: {
                        actions: [],
                },
                isEditEnabled: false,
        },
        show: {
                groups: [
                        {
                                name: "Inquiry Details",
                                type: "property",
                                value: (resource) => omit(["id"], resource),
                        },
                ],
        },
}

export default Shared.defineAdminModel({
        key: "inquiry",
        collectionKey: "inquiries",
        collectionName: "Inquiries",
        attributes,
        filters,
        Request,
        Response,
        Api,
        TableHeaders,
        Pages,
})
