export function init(window) {
        const utilities = window._utilities = window._utilities || Object.create(null)

        utilities.debounce = function debounce(fn, delay) {
                let timeoutID = null
                return function(...args) {
                        clearTimeout(timeoutID)
                        const that = this
                        timeoutID = setTimeout(function() {
                                fn.apply(that, args)
                        }, delay)
                }
        }

        utilities.isDmsEnabled = function isDmsEnabled() {
                return !!(window.App && window.App.isDmsEnabled)
        }

        utilities.runIf = function runIf({ dmsFn, defaultFn } = { dmsFn: null, defaultFn: null }) {
                if (!(dmsFn || defaultFn)) {
                        throw new Error('Either DMS or Default function should be defined')
                }

                let fn = () => { }
                if (utilities.isDmsEnabled()) {
                        fn = dmsFn || fn
                } else {
                        fn = defaultFn || fn
                }

                return fn()
        }
}

export function getImageUrl(path) {
        const result = new URL(`/assets/images/${path}`, import.meta.url).href
        return result
}

export function forceLogout(from) {
        useCredentials().clear()
        if (from && from.path.startsWith("/admin")) {
                useRouter().push("/admin/login")
                return
        }
        useRouter().push("/users/sign_in")
}

export const ACCESS_RIGHT = {
        MODULE: {
                Dashboard: "Dashboard",
                Campaign: "Campaigns",
                IvrTree: "Call Flows",
                Reports: "Reports",
                Billing: "Billing",
                Files: "Files",
                User: "User Access",
        },
        PERMISSION: {
                ViewDashboard: "View",
                IndexCampaign: "View/Search",
                CreateCampaign: "Add",
                IndexCallFlow: "View/Search",
                CreateGroup: "Add Group",
                TransferGroup: "Transfer Group",
                ViewCallFlow: "View",
                CreateCallFlow: "Add",
                EditCallFlow: "Edit",
                DuplicateCallFlow: "Duplicate",
                DeleteCallFlow: "Delete",
                ApproveCallFlow: "Approve",
                DownloadReports: "Download",
                ViewInvoice: "View",
                IndexCampaignFile: "View Campaign",
                DownloadCampaignFile: "Download Campaign",
                IndexKillList: "View Kill List",
                DownloadKillList: "Download Kill List",
                IndexSubUser: "List",
                AddSubUser: "Add",
                EditSubUser: "Edit",
                DeactivateSubUser: "Deactivate",
        },
}

const spacify = (value) => String(value).replaceAll("_", " ")
const capitalize = (value) => {
        value = String(value)
        return value.charAt(0).toUpperCase() + value.slice(1)
}
const titleize = (value) => String(value).split(" ").map(capitalize).join(" ")
export function normalizeKey(value) {
        return titleize(spacify(value))
}
