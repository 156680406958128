import { z } from "zod"

export const Request = {
        Update: z.object({
                user: z.object({
                        first_name: z.string(),
                        last_name: z.string(),
                        mobile: z.string(),
                        email: z.string(),
                        designation: z.string(),
                        company: z.string(),
                        company_address: z.string(),
                        industry: z.string(),
                        password: z.string(),
                })
        }).safeParse,

        ChangePassword: z.object({
                user: z.object({
                        current_password: z.string(),
                        password: z.string(),
                        password_confirmation: z.string(),
                })
        }).safeParse,

        VerifyEmail: z.object({ confirmation_token: z.string() }).safeParse,

        VerifyMobtel: z.object({
                user: z.object({
                        pin_one: z.coerce.number(),
                        pin_two: z.coerce.number(),
                        pin_three: z.coerce.number(),
                        pin_four: z.coerce.number(),
                })
        }).safeParse,
}

export const Response = {
        Details: z.object({
                data: z.object({
                        first_name: z.string(),
                        last_name: z.string(),
                        mobile: z.string(),
                        email: z.string(),
                        designation: z.string(),
                        company: z.string(),
                        company_address: z.string(),
                        industry: z.string(),
                }),
        }).safeParse,

        FormOptions: z.object({
                user_industries: z.string().array(),
        }).safeParse,

        VerifyEmail: z.object({
                mobile: z.string(),
        }).safeParse,
}
