import { z } from "zod"
import { empty, omit, pick } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        to: { type: String, required: true },
        bcc: { type: String },
        subject: { type: String, required: true },
        account_name: { type: String, required: true },
        body: { type: String, required: true },
}

const filters = [
        { key: "to", group: "text" },
        { key: "subject", group: "text" },
        { key: "body", group: "text" },
        { key: "bcc", group: "text" },
        { key: "account_name", group: "text" },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
]

const Request = ({ helpers, config }) => ({
        Create: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes,
                }))
                return z.object({ [config.key]: form }).safeParse(data)
        },
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
})

const Response = ({ helpers, config }) => ({
        List: z.object({
                data: z.object({
                        id: z.number(),
                        to: z.string(),
                        bcc: z.string(),
                        subject: z.string(),
                        body: z.string(),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({ attributes })
        }).safeParse,
})

export const Form = ({ helpers, config }) => ({
        Create: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: ([attribute, value]) => {
                        const result = {}
                        switch (attribute) {
                                case "body": {
                                        result.type = "textarea"
                                } break
                        }
                        return result
                },
        }),
})

const Api = ({ helpers, config }) => ({
        Create: {
                bodyParser: (options = { form: {} }) => config.Request.Create({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Delete: empty(Object),
})

const TableHeaders = [
        { title: "Id", key: "id" },
        { title: "To", key: "to" },
        { title: "Bcc", key: "bcc" },
        { title: "Subject", key: "subject" },
        { title: "Body", key: "body" },
]

export const Pages = ({ helpers, config }) => ({
        list: {
                isEditEnabled: false,
                actions: [
                        {
                                name: "Delete",
                                handle: "delete",
                        },
                ]
        },
        show: {
                actions: [
                        {
                                name: "Delete Custom Email",
                                operation: {
                                        name: "delete",
                                        enabled: () => true,
                                        pathAfter: "/admin/custom_emails"
                                },
                        },
                ],
                groups: [
                        {
                                name: "Custom Email Details",
                                type: "property",
                                value: (resource) => omit(["id"], resource),
                        },
                ],
        },
})

export default Shared.defineAdminModel({
        key: "custom_email",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        TableHeaders,
        Pages,
})
