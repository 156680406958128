import { z } from "zod"
import { empty, pick, transformers } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        details: { type: String, disabled: true },
        uuid: { type: String, disabled: true },
        conversation_uuid: { type: String, disabled: true },
        caller_identifier: { type: String, disabled: true },
        raw_duration: { type: Integer, disabled: true },
        rate_label: { type: String, disabled: true, nullable: true },

        start_time: { type: Date, disabled: true, nullable: true },
        end_time: { type: Date, disabled: true, nullable: true },
        created_at: { type: Date, disabled: true, form: false },
        updated_at: { type: Date, disabled: true },

        remarks: { type: String, disabled: true, nullable: true },
        status: { type: Integer },
}

const filters = [
        { key: "status", group: "number" },
        { key: "uuid", group: "text" },
        { key: "conversation_uuid", group: "text" },

        { key: "start_time", group: "date" },
        { key: "end_time", group: "date" },

        { key: "raw_duration", group: "number" },
        { key: "rate_label", group: "text" },
        { key: "caller_identifier", group: "text" },
        { key: "remarks", group: "text" },
        { key: "updated_at", group: "date" },
]

const Request = ({ helpers, config }) => ({
        List: z.object({
                ...Shared.Request.Paginated(),
        }).safeParse,
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form })
                        .transform(transformers.object.toFormData)
                        .safeParse(data)
        },
})

const Response = ({ helpers, config }) => ({
        FormOptions: z.object({
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        details: z.string(),
                        status: z.number(),
                        call_status: z.string(),
                        uuid: z.string(),
                        conversation_uuid: z.string(),
                        caller_identifier: z.string(),
                        raw_duration: z.number(),
                        rate_label: z.string().nullable(),
                        remarks: z.string().nullable(),
                        ...Shared.Response.Timestamps(["updated_at"]),
                        start_time: z.coerce.date().nullable(),
                        end_time: z.coerce.date().nullable(),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({ exclude: [], attributes }),
                details: z.string().optional(),
                campaign_record: z.number().nullable(),
                blast_attempt: z.number().nullable(),
                applied_computation_at: z.coerce.date().nullable(),
                deducted_to_regular_credits: z.boolean(),
        }).safeParse,
})

const transformer = ([attribute, value]) => {
        const result = {}
        switch (attribute) {
                case "status": {
                        result.options = []
                        result.option_key = "statuses"
                } break
        }
        return result
}

const Form = ({ helpers, config }) => ({
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
})

const Api = ({ helpers, config }) => ({
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form
                }),
                errorParser: Shared.Response.Errors,
        },
})

const TableHeaders = () => Shared.TableHeaders([
        "id",
        "details",
        "status",
        "call_status",
        "uuid",
        "conversation_uuid",
        "caller_identifier",
        "raw_duration",
        "rate_label",
        "remarks",
        { key: "start_time", format: (v) => v ? formatters.date(v) : "" },
        { key: "end_time", format: (v) => v ? formatters.date(v) : "" },
        { key: "updated_at", format: formatters.date },
])

const Pages = ({ helpers, config }) => ({
        show: {
                groups: [
                        {
                                name: "Sms Masking Details",
                                type: "property",
                                value: (resource) => {
                                        const result = {}

                                        result.campaign_record = `Campaign record #${resource.campaign_record}`
                                        result.blast_attempt = `Blast attempt #${resource.blast_attempt}`
                                        result.status = resource.status
                                        result.conversation_uuid = resource.conversation_uuid
                                        result.uuid = resource.uuid
                                        result.created_at = resource.created_at
                                        result.updated_at = resource.updated_at
                                        result.end_time = resource.end_time
                                        result.start_time = resource.start_time
                                        result.raw_duration = resource.raw_duration
                                        result.rate_label = resource.rate_label
                                        result.remarks = resource.remarks
                                        result.caller_identifier = resource.caller_identifier
                                        result.applied_computation_at = resource.applied_computation_at
                                        result.deducted_to_regular_credits = resource.deducted_to_regular_credits

                                        return result
                                },
                        },
                ],
        },
})

export default Shared.defineAdminModel({
        key: "outbound_call_transaction",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        TableHeaders,
        Pages,
})
