import { z } from "zod"

export const Request = { /* TODO(aes) */ }

export const Response = {
        FormOptions: z.object({
                caller_id_select_options: z.string().array(),
                non_globe_caller_id_select_options: z.string().array(),
                default_caller_id: z.string(),
                sms_masking_for_listing: z.string().array(),
                voices: z.tuple([z.string(), z.number()]).array(),
        }).safeParse
}
