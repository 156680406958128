import { z } from "zod"
import { empty, pick } from "@schtappe/utils"

import * as Definitions from "./definitions.js"
import * as Shared from "./shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal, INPUT_MAP } = Definitions

const attributes = {}

const filters = [
        { key: "users", group: "select_model", option_key: "users" }, // model -> options, ${key}_id_eq
        { key: "sms_maskings", group: "select_model", option_key: "sms_maskings" }, // model -> options, ${key}_id_eq
        { key: "company", group: "text" }, // text -> ${key}_contains | ${key}_equals | ${key}_starts_with | ${key}_ends_with
        { key: "industry", group: "select_fixed", option_key: "industries", option_type: String }, // fixed -> options.fixed, ${key}_eq
        { key: "queuing_priority", group: "select_fixed", option_key: "queuing_priorities", option_type: Number },
        { key: "can_blast_anytime", group: "select_boolean" }, // boolean -> options.yesno, ${key}_eq
        { key: "client_group", group: "select_fixed", option_key: "client_groups", option_type: Number },
        { key: "accepted_terms_and_conditions", group: "select_boolean" },
]

export const Request = ({ helpers, config }) => ({
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
        Wizard: (data) => {
                if (data == Symbol.for("empty")) {
                        return {
                                signup_form: {
                                        first_name: "",
                                        last_name: "",
                                        email: "",
                                        mobile: "",
                                        designation: "",
                                        company: "",
                                        company_address: "",
                                        industry: "Others",
                                        app_id: "",
                                        app_secret: "",
                                        passphrase: "",
                                        walleted: true, // NOTE(aes): dms_enabled == !walleted
                                        attempts_feature: "single",
                                        reattempt_count: 0,
                                }
                        }
                }

                return z.object({
                        signup_form: z.object({
                                first_name: z.string(),
                                last_name: z.string(),
                                email: z.string(),
                                mobile: z.string(),
                                designation: z.string(),
                                company: z.string(),
                                company_address: z.string(),
                                industry: z.string(),
                                app_id: z.string(),
                                app_secret: z.string(),
                                passphrase: z.string(),
                                walleted: z.boolean(),
                                attempts_feature: z.union([z.literal("single"), z.literal("multiple")]),
                                reattempt_count: z.number(),
                        })
                }).safeParse(data)
        },
        WizardFinish: z.object({
                plan_selection: z.number(),
        }).safeParse,
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return {
                                account: {
                                        company: "",
                                        company_address: "",
                                        client_group: 0,
                                        industry: "Others",

                                        app_id: "",
                                        app_secret: "",
                                        passphrase: "",

                                        walleted: true, // NOTE(aes): dms_enabled == !walleted
                                        attempts_feature: "single",
                                        reattempt_count: 0,

                                        can_blast_anytime: false,
                                        can_generate_usage_extraction: false,
                                        queuing_priority: 0,
                                        primary_user_attributes: {
                                                id: 0,
                                                first_name: "",
                                                last_name: "",
                                                email: "",
                                                mobile: "",
                                        },
                                        accepted_terms_and_conditions: false,
                                }
                        }
                }

                return z.object({
                        account: z.object({
                                company: z.string(),
                                company_address: z.string(),
                                client_group: z.number(),
                                industry: z.string(),

                                app_id: z.string().optional(),
                                app_secret: z.string().optional(),
                                passphrase: z.string().optional(),

                                walleted: z.boolean(),
                                attempts_feature: z.union([z.literal("single"), z.literal("multiple")]),
                                reattempt_count: z.number(),

                                can_blast_anytime: z.boolean(),
                                can_generate_usage_extraction: z.boolean(),
                                queuing_priority: z.number(),
                                primary_user_attributes: z.object({
                                        id: z.number(),
                                        first_name: z.string(),
                                        last_name: z.string(),
                                        email: z.string(),
                                        mobile: z.string(),
                                }),
                                accepted_terms_and_conditions: z.boolean(),
                        })
                }).safeParse(data)
        },
})

export const Response = {
        FilterOptions: z.object({
                industries: z.tuple([z.string("name"), z.string("name")]).array(),
                queuing_priorities: z.tuple([z.string("name"), z.number("id")]).array(),
                client_groups: z.tuple([z.string("name"), z.number("id")]).array(),
                users: z.tuple([z.string("name"), z.number("id")]).array(),
                sms_maskings: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        name: z.string(),
                        email: z.string(),
                        mobile_no: z.string(),
                        company: z.string(),
                        status: z.enum([
                                "created",
                                "pending",
                                "trial",
                                "activated",
                                "deactivated",
                        ]),
                        can_blast_anytime: z.boolean(),
                        can_generate_usage_extraction: z.boolean(),
                        queuing_priority: z.enum([
                                "low",
                                "medium",
                                "high"
                        ]),
                        tnc: z.enum([
                                "waiting",
                                "accepted",
                        ]),
                        created_at: z.coerce.date({ offset: true }),
                }).array(),
                current_page: z.number(),
                page_total: z.number(),
                per_page: z.number(),
                total_items: z.number(),
        }).safeParse,
        FormOptions: z.object({
                industries: z.tuple([z.string("name"), z.string("name")]).array(),
                plans: z.object({
                        id: z.number(),
                        name: z.string(),
                        description: z.string(),
                }).array(),
                queuing_priorities: z.tuple([z.string("name"), z.number("id")]).array(),
                account_client_group_select_options: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        WizardFinish: z.object({
                id: z.number(),
        }).safeParse,
        Show: z.object({
                id: z.number(),

                primary_user: z.object({
                        id: z.number(),
                        full_name: z.string(),
                        email: z.string(),
                        mobile: z.string(),
                        designation: z.string(),
                        first_name: z.string(),
                        last_name: z.string(),
                }),

                company_details: z.object({
                        company: z.string(),
                        company_address: z.string(),
                        industry: z.string(),
                        tnc: z.enum([
                                "waiting",
                                "accepted",
                        ]),
                        client_group: z.number(),
                }),

                sms_api_details: z.object({
                        sms_app_id: z.string().nullable(),
                        sms_app_secret: z.string().nullable(),
                        sms_passphrase: z.string().nullable(),
                        can_blast_anytime: z.boolean(),
                        can_generate_usage_extraction: z.boolean(),
                }),

                created_at: z.coerce.date({ offset: true }),
                updated_at: z.coerce.date({ offset: true }),

                account_plan: z.object({
                        id: z.number(),
                        name: z.string(),
                        sms_credits: z.number(),
                        call_credits: z.number(),
                        price: z.coerce.number(),
                        credit_limit: z.coerce.number(),
                        base_call_usage_duration: z.number(),
                        cross_call_rate: z.coerce.number(),
                        call_rate: z.coerce.number(),
                        nominated_caller_id_rate: z.coerce.number(),
                }),

                account_campaign_settings: z.object({
                        id: z.number(),
                        automated_via_sftp: z.boolean(),
                        sftp_host: z.string().nullable(),
                        sftp_path: z.string().nullable(),
                        sftp_username: z.string().nullable(),
                        sftp_password: z.string().nullable(),
                        pemfile: z.object({
                                url: z.string().nullable(),
                        }),
                }),

                account_call_feature: z.object({
                        walleted: z.boolean(),
                        attempts_feature: z.enum([
                                "single",
                                "multiple",
                        ]),
                        reattempt_count: z.number(),
                }),

                account_wallet: z.object({
                        id: z.number(),
                        call_credits: z.number(),
                        sms_credits: z.number(),
                        consumed_call_credits: z.number(),
                        consumed_sms_credits: z.number(),
                        credit_limit: z.coerce.number(),
                        base_call_usage_duration: z.number(),
                        cross_call_rate: z.coerce.number(),
                        call_rate: z.coerce.number(),
                        nominated_caller_id_rate: z.coerce.number(),
                        expiration_date: z.coerce.date({ offset: true }),
                }),

                caller_ids: z.object({
                        id: z.number(),
                        number: z.string(),
                        status: z.string(),
                        provision_type: z.string(),
                        created_at: z.coerce.date({ offset: true }),
                        updated_at: z.coerce.date({ offset: true }),
                }).array(),

                sms_maskings: z.object({
                        id: z.number(),
                        masking_name: z.string(),
                        status: z.string().nullable(),
                        created_at: z.coerce.date({ offset: true }),
                        updated_at: z.coerce.date({ offset: true }),
                }).array(),

                queuing_priority: z.enum([
                        "low",
                        "medium",
                        "high"
                ]),

                display_name: z.string(),
                accepted_terms_and_conditions: z.boolean(),
        }).safeParse,
}

export const Form = {}

export const Api = ({ helpers, config }) => ({
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        Wizard: {
                bodyParser: (options = { form: {} }) => config.Request.Wizard({
                        signup_form: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
        WizardFinish: {
                bodyParser: (options = { plan_selection: 0 }) => config.Request.WizardFinish({
                        plan_selection: options.plan_selection,
                }),
                successParser: config.Response.WizardFinish,
                errorParser: Shared.Response.Errors,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        account: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
})

export const Scopes = () => empty(Array)

export const TableHeaders = () => Shared.TableHeaders([
        { title: "Id", key: "id" },
        { title: "Name", key: "name" },
        { title: "Email", key: "email" },
        { title: "Mobile No", key: "mobile_no" },
        { title: "Company", key: "company" },
        { title: "Status", key: "status" },
        { title: "Can Blast Anytime", key: "can_blast_anytime" },
        { title: "Can Generate Usage Extraction", key: "can_generate_usage_extraction" },
        { title: "Queuing Priority", key: "queuing_priority" },
        {
                title: "T&C",
                key: "tnc",
                format: (v) => typeof v == "string" ? v.toLocaleUpperCase() : "",
                badged: (v) => v !== "waiting",
        },
        { title: "Created At", key: "created_at", format: formatters.date },
])

const createDropdown = ({ id }) => ([
        {
                link: `/admin/accounts/${id}/account_requested_plans?scope=for_approval`,
                name: "Plans",
        },
        {
                link: `/admin/accounts/${id}/caller_ids?scope=for_approval`,
                name: "Caller IDs",
        },
        {
                link: `/admin/accounts/${id}/sms_maskings?scope=for_approval`,
                name: "SMS Maskings",
        },
])

export const Pages = ({ helpers, config }) => ({
        list: {
                actions: [{ name: "Requests", dropdown: createDropdown }],
        },
        new: {
                path: "wizard",
        },
        show: {
                title: ({ resource }) => resource.display_name,
                actions: [
                        { name: "Edit Account", path: "edit" },
                        { name: "Requests", dropdown: createDropdown },
                ],
                groups: [
                        {
                                name: "Primary User",
                                type: "property",
                                value: (resource) => pick(["full_name", "email", "mobile_number", "designation"], resource?.primary_user || {}),
                        },
                        {
                                name: "Company Details",
                                type: "property",
                                value: (resource) => {
                                        const result = pick(["company", "company_address", "industry"], resource?.company_details || {})
                                        result["T&C"] = {
                                                value: resource?.company_details?.tnc,
                                                format: (v) => typeof v == "string" ? v.toLocaleUpperCase() : "",
                                                badged: (v) => v !== "waiting",
                                        }
                                        return result
                                },
                        },
                        {
                                name: "SMS API Details",
                                type: "property",
                                value: (resource) => resource?.sms_api_details,
                        },
                        {
                                name: "",
                                type: "property",
                                value: (resource) => ({ created_at: resource.created_at, updated_at: resource.updated_at }),
                        },
                        {
                                name: "Account Plan",
                                type: "property",
                                value: (resource) => {
                                        const result = pick(
                                                [
                                                        "name",
                                                        "sms_credits",
                                                        "call_credits",
                                                        "price",
                                                        "credit_limit",
                                                        "base_call_usage_duration",
                                                        "cross_call_rate",
                                                        "call_rate",
                                                        "nominated_caller_id_rate"
                                                ],
                                                resource?.account_plan || {}
                                        )

                                        return result
                                },
                                actions: [
                                        {
                                                name: "Edit Account Plan",
                                                link: ({ resource }) => `/admin/account_plans/${resource?.account_plan?.id}/edit`
                                        },
                                ],
                        },
                        {
                                name: "Account Campaign Settings",
                                type: "property",
                                value: (resource) => {
                                        const result = pick([
                                                "automated_via_sftp",
                                                "sftp_host",
                                                "sftp_path",
                                                "sftp_username",
                                                "sftp_password",
                                        ], resource?.account_campaign_settings)

                                        result.pemfile = resource?.account_campaign_settings?.url

                                        return result
                                },
                                actions: [
                                        {
                                                name: "Edit Campaign Settings",
                                                link: ({ resource }) => `/admin/campaign_settings/${resource?.account_campaign_settings?.id}/edit`
                                        },
                                ],
                        },
                        {
                                name: "Account Call Feature",
                                type: "property",
                                value: (resource) => resource?.account_call_feature,
                        },
                        {
                                name: "Account Wallet",
                                type: "property",
                                value: (resource) => {
                                        const result = pick([
                                                "call_credits",
                                                "sms_credits",
                                                "consumed_call_credits",
                                                "consumed_sms_credits",
                                                "credit_limit",
                                                "base_call_usage_duration",
                                                "cross_call_rate",
                                                "call_rate",
                                                "nominated_caller_id_rate",
                                                "expiration_date",
                                        ], resource?.account_wallet)
                                        return result
                                },
                                actions: [
                                        {
                                                name: "Edit Account Wallet",
                                                link: ({ resource }) => `/admin/wallets/${resource?.account_wallet?.id}/edit`
                                        },
                                ],
                        },
                        {
                                name: "Caller IDs",
                                type: "table",
                                headers: () => [
                                        { title: "Id", key: "id" },
                                        { title: "Number", key: "number" },
                                        { title: "Status", key: "status" },
                                        { title: "Provision Type", key: "provision_type" },
                                        { title: "Created At", key: "created_at", format: formatters.date },
                                        { title: "Updated At", key: "updated_at", format: formatters.date },
                                ],
                                items: (resource) => resource?.caller_ids,
                        },
                        {
                                name: "SMS Maskings",
                                type: "table",
                                headers: () => [
                                        { title: "Id", key: "id" },
                                        { title: "Masking Name", key: "masking_name" },
                                        { title: "Status", key: "status" },
                                        { title: "Created At", key: "created_at", format: formatters.date },
                                        { title: "Updated At", key: "updated_at", format: formatters.date },
                                ],
                                items: (resource) => resource?.sms_maskings,
                        },
                        {
                                name: "Queuing Priority",
                                type: "property",
                                value: (resource) => pick(["queuing_priority"], resource),
                        },
                ],
        },
        wizard: {
                title: "Step1",
                groups: () => [
                        {
                                title: "Account - Primary User",
                                fields: [
                                        {
                                                attribute: "first_name",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "last_name",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "email",
                                                required: true,
                                                type: "email",
                                        },
                                        {
                                                attribute: "mobile",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "designation",
                                                required: true,
                                                type: "text",
                                        },
                                ],
                        },
                        {
                                title: "Account - Company Details",
                                fields: [
                                        {
                                                attribute: "company",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "company_address",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "industry",
                                                required: true,
                                                type: "select",
                                                options: [],
                                                option_key: "industries",
                                        },
                                        {
                                                attribute: "app_id",
                                                title: "SMS App ID",
                                                type: "text",
                                        },
                                        {
                                                attribute: "app_secret",
                                                title: "SMS App Secret",
                                                type: "text",
                                        },
                                        {
                                                attribute: "app_passphrase",
                                                title: "SMS App Passphrase",
                                                type: "text",
                                        },
                                ],
                        },
                        {
                                title: "Account - Call Feature",
                                fields: [
                                        {
                                                attribute: "walleted",
                                                type: "checkbox",
                                        },
                                        {
                                                attribute: "attempts_feature",
                                                type: "radio",
                                                choices: ["single", "multiple"],
                                                disabled: true,
                                        },
                                        {
                                                attribute: "reattempt_count",
                                                type: "number",
                                                disabled: true,
                                        },
                                ],
                        },
                ],
        },
        edit: {
                groups: () => [
                        {
                                title: "Account - Company Details",
                                fields: [
                                        {
                                                attribute: "company",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "company_address",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "client_group",
                                                required: true,
                                                type: "select",
                                                options: [],
                                                option_key: "account_client_group_select_options",
                                        },
                                        {
                                                attribute: "industry",
                                                required: true,
                                                type: "select",
                                                options: [],
                                                option_key: "industries",
                                        },
                                ],
                        },
                        {
                                title: "Account - Call Feature",
                                fields: [
                                        {
                                                attribute: "walleted",
                                                type: "checkbox",
                                        },
                                        {
                                                attribute: "attempts_feature",
                                                type: "radio",
                                                choices: ["single", "multiple"],
                                                disabled: true,
                                        },
                                        {
                                                attribute: "reattempt_count",
                                                type: "number",
                                                disabled: true,
                                        },
                                ],
                        },
                        {
                                title: "Account - SMS API Details",
                                fields: [
                                        {
                                                attribute: "app_id",
                                                title: "SMS App ID",
                                                type: "text",
                                        },
                                        {
                                                attribute: "app_secret",
                                                title: "SMS App Secret",
                                                type: "text",
                                        },
                                        {
                                                attribute: "app_passphrase",
                                                title: "SMS App Passphrase",
                                                type: "text",
                                        },
                                        {
                                                attribute: "can_blast_anytime",
                                                type: "checkbox",
                                        },
                                        {
                                                attribute: "can_generate_usage_extraction",
                                                type: "checkbox",
                                        },
                                ],
                        },
                        {
                                title: "Account - Queuing Priority",
                                fields: [
                                        {
                                                attribute: "queuing_priority",
                                                type: "select",
                                                options: [],
                                                option_key: "queuing_priorities",
                                        },
                                ],
                        },
                        {
                                title: "Account - Primary User",
                                nested: "primary_user_attributes",
                                fields: [
                                        {
                                                attribute: "first_name",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "last_name",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "email",
                                                required: true,
                                                type: "email",
                                        },
                                        {
                                                attribute: "mobile",
                                                required: true,
                                                type: "text",
                                        },
                                        {
                                                attribute: "designation",
                                                type: "text",
                                                disabled: true,
                                        },
                                        {
                                                attribute: "accepted_terms_and_conditions",
                                                type: "checkbox",
                                        },
                                ],
                        },
                ],
        },
})

export default Shared.defineAdminModel({
        key: "account",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        Scopes,
        TableHeaders,
        Pages,
})
