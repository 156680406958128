import { z } from "zod"
import { empty, pick, omit } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, form: false },
        plan_template: { type: Integer },
        name: { type: String },
        sms_credits: { type: Integer },
        call_credits: { type: Integer },
        price: { type: Decimal },
        credit_limit: { type: Decimal },
        base_call_usage_duration: { type: Integer },
        cross_call_rate: { type: Decimal },
        call_rate: { type: Decimal },
        nominated_caller_id_rate: { type: Decimal },
}

const Request = ({ helpers, config }) => ({
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form }).safeParse(data)
        },
})

const Response = ({ helpers, config }) => ({
        FormOptions: z.object({
                plan_templates: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({
                        attributes: config.attributes,
                }),
                account_id: z.number(),
        }).safeParse,
        Plan: z.object({
                name: z.string(),
                sms_credits: z.number(),
                call_credits: z.number(),
                price: z.coerce.number(),
                credit_limit: z.coerce.number(),
                base_call_usage_duration: z.number(),
                cross_call_rate: z.coerce.number(),
                call_rate: z.coerce.number(),
                nominated_caller_id_rate: z.coerce.number(),
        }).safeParse,
})

const Form = ({ helpers, config }) => ({
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: ([attribute, value]) => {
                        const result = {}
                        switch (attribute) {
                                case "plan_template": {
                                        result.options = []
                                        result.option_key = "plan_templates"
                                } break
                                case "name": {
                                        result.title = "Plan Name"
                                } break
                        }
                        return result
                },
        }),
})

const Api = ({ helpers, config }) => ({
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
})

const TableHeaders = [
]

const Pages = {
}

export default Shared.defineAdminModel({
        key: "account_plan",
        attributes,
        Request,
        Response,
        Form,
        Api,
        TableHeaders,
        Pages,
})
