import { createStore } from "vuex";
import axios from "axios"

function save(key, value) {
        if (!sessionStorage) { return null }
        sessionStorage.setItem(key, value)
}
function retrieve(key) {
        if (!sessionStorage) { return null }
        try {
                return JSON.parse(sessionStorage.getItem(key))
        } catch (e) {
                return sessionStorage.getItem(key)
        }
}

function getPaginationObject(data) {
        return {
                current_page: data.current_page,
                page_total: data.page_total,
                per_page: data.per_page,
                total_items: data.total_items
        }
}

function defaultPagination() {
        return {
                total_items: 0,
                per_page: 0,
                current_page: 0,
                page_total: 0
        }
}

function flattenAccessRights(permissions) {
        return permissions.flatMap((permission) => {
                return permission.access.map((a) => {
                        const accessRight = {
                                module: permission.module,
                                permission: a.id,
                                enabled: a.enabled
                        }

                        if (a.access_right_id) {
                                accessRight.id = a.access_right_id
                        }

                        return accessRight
                })
        }).reduce((permissionAttributes, permission, index) => {
                permissionAttributes[index] = permission
                return permissionAttributes
        }, {})
}

export default defineNuxtPlugin((nuxtApp) => {
        const http = nuxtApp.$http

        const store = createStore({
                state() {
                        return {
                                ivrTreeGroups: [],
                                ivrTreeGroupsPagination: defaultPagination(),
                                users: [],
                                usersPagination: { ...defaultPagination(), per_page: 10 },
                                activeIvrGroup: retrieve('activeIvrGroup') || {},
                                guard: {}
                        }
                },
                getters: {
                        getActiveIvrGroup(state) {
                                if (!state.activeIvrGroup.name) {
                                        return { name: 'No Group' }
                                }

                                return state.activeIvrGroup
                        },
                        getIvrTreeGroups(state) {
                                return state.ivrTreeGroups
                        },
                        getGuard(state) {
                                return (name) => state.guard[name]
                        },
                        hasMoreIvrGroups({ ivrTreeGroupsPagination: pagination }) {
                                return pagination.page_total > pagination.current_page
                        },
                        getCurrentPage({ ivrTreeGroupsPagination: pagination }) {
                                return pagination.current_page
                        },
                        getUsers(state) {
                                return state.users
                        }
                },
                mutations: {
                        SET_ACTIVE_IVR_GROUP(state, ivrGroup) {
                                state.activeIvrGroup = ivrGroup
                        },
                        SET_IVR_TREE_GROUPS(state, ivrTreeGroups) {
                                state.ivrTreeGroups = ivrTreeGroups
                        },
                        SET_IVR_TREE_GROUPS_PAGINATION(state, pagination) {
                                state.ivrTreeGroupsPagination = pagination
                        },
                        SET_GUARD(state, { name, value }) {
                                state.guard[name] = value
                        },
                        SET_USERS(state, users) {
                                state.users = users
                        },
                        SET_USERS_PAGINATION(state, pagination) {
                                state.usersPagination = pagination
                        }
                },
                actions: {
                        selectIvrGroup({ commit }, ivrGroup = { id: 0, name: '' }) {
                                if (!(typeof ivrGroup === 'object')) { return }

                                commit('SET_ACTIVE_IVR_GROUP', ivrGroup)
                                save('activeIvrGroup', JSON.stringify(ivrGroup))
                        },
                        async fetchIvrTreeGroups({ commit, getters }, { page, per, append } = { page: 0, per: 10, append: false }) {
                                try {
                                        const { data } = await http.get('/ivr_tree_groups', {
                                                params: {
                                                        page,
                                                        per,
                                                        sorted_by: 'name_asc'
                                                }
                                        })

                                        if (append) {
                                                commit('SET_IVR_TREE_GROUPS', [...getters.getIvrTreeGroups, ...data.data])
                                        } else {
                                                commit('SET_IVR_TREE_GROUPS', data.data)
                                        }
                                        commit('SET_IVR_TREE_GROUPS_PAGINATION', getPaginationObject(data))
                                } catch (e) {
                                        return Promise.reject(e)
                                }
                        },
                        async setDefaultIvrTreeGroup({ commit, getters, dispatch }, { forceUpdate } = { forceUpdate: false }) {
                                const activeGroup = getters.getActiveIvrGroup
                                const isActiveGroupInList = getters.getIvrTreeGroups.some(
                                        (ivrTreeGroup) => ivrTreeGroup.id === activeGroup.id
                                )
                                if (!forceUpdate && activeGroup.id && isActiveGroupInList) { return }

                                let newActiveGroup = null
                                await dispatch('guardAction', {
                                        name: 'ivrTreeGroups',
                                        action: () => dispatch('fetchIvrTreeGroups', { page: 1, per: 50 })
                                })
                                if (activeGroup.id) {
                                        while (
                                                !getters.getIvrTreeGroups.some((ivrTreeGroup) => ivrTreeGroup.id === activeGroup.id) &&
                                                getters.hasMoreIvrGroups
                                        ) {
                                                await dispatch('guardAction', {
                                                        name: 'ivrTreeGroups',
                                                        action: () => dispatch('fetchIvrTreeGroups', { page: getters.getCurrentPage + 1, per: 50, append: true })
                                                })
                                        }

                                        newActiveGroup = getters.getIvrTreeGroups
                                                .find((ivrTreeGroup) => ivrTreeGroup.id === activeGroup.id) || getters.getIvrTreeGroups[0]
                                } else {
                                        newActiveGroup = getters.getIvrTreeGroups[0]
                                }

                                commit('SET_ACTIVE_IVR_GROUP', newActiveGroup || {})
                        },
                        async guardAction({ commit, getters }, { name, action } = { name: '', action: () => { } }) {
                                if (getters.getGuard(name)) { return }

                                try {
                                        const guard = Promise.resolve(action())
                                        commit('SET_GUARD', { name, value: guard })
                                        await guard
                                } catch (e) {
                                        return Promise.reject(e)
                                } finally {
                                        commit('SET_GUARD', { name, value: null })
                                }
                        },
                        async duplicateIvrTree(_, { id, name } = { id: 0, name: '' }) {
                                try {
                                        const { data } = await http.post(`/ivr_trees/${id}/duplicate`, { new_ivr_name: name })
                                        return data
                                } catch (e) {
                                        const errors = (e && e.response && e.response.data && e.response.data.errors) ||
                                                ['Unable to duplicate call flow']
                                        return Promise.reject(errors)
                                }
                        },
                        async approveIvr(_, ivrId) {
                                if (!ivrId) {
                                        return Promise.reject(['Id should not be undefined'])
                                }

                                try {
                                        const { data } = await http.post(`/ivr_trees/${ivrId}/approval`, { approval_status: 'approved' })
                                        return data
                                } catch (e) {
                                        const errors = (e && e.response && e.response.data && e.response.data.errors) ||
                                                ['Unable to approve call flow']
                                        return Promise.reject(errors)
                                }
                        },
                        async fetchUsers({ commit }, { page, per } = { page: 0, per: 10 }) {
                                try {
                                        const { data } = await http.get('/sub_users/users.json', {
                                                params: {
                                                        page,
                                                        per
                                                }
                                        })

                                        commit('SET_USERS', data.data)
                                        commit('SET_USERS_PAGINATION', getPaginationObject(data))
                                } catch (e) {
                                        return Promise.reject(e)
                                }
                        },
                        async fetchNewUser() {
                                try {
                                        const { data } = await http.get('/sub_users/users/new.json')
                                        return data
                                } catch (e) {
                                        return Promise.reject(['Unable to fetch new user'])
                                }
                        },
                        async addSubUser(_, { info, permissions } = { info: {}, permissions: [] }) {
                                const {
                                        account_id,
                                        firstName: first_name,
                                        lastName: last_name,
                                        email,
                                        mobile
                                } = info

                                try {
                                        const { data } = await http.post('/sub_users/users', {
                                                user: {
                                                        account_id,
                                                        first_name,
                                                        last_name,
                                                        email,
                                                        mobile,
                                                        access_rights_attributes: flattenAccessRights(permissions)
                                                }
                                        })
                                        return data
                                } catch (e) {
                                        const errors = (e && e.response && e.response.data && e.response.data.errors) ||
                                                ['Unable to add new user']
                                        return Promise.reject(errors)
                                }
                        },
                        async updateSubUser(_, { info, permissions } = { info: {}, permissions: [] }) {
                                const {
                                        id,
                                        firstName: first_name,
                                        lastName: last_name,
                                        email,
                                        mobile
                                } = info

                                try {
                                        const { data } = await http.patch(`/sub_users/users/${id}`, {
                                                user: {
                                                        first_name,
                                                        last_name,
                                                        email,
                                                        mobile,
                                                        access_rights_attributes: flattenAccessRights(permissions)
                                                }
                                        })
                                        return data
                                } catch (e) {
                                        const errors = (e && e.response && e.response.data && e.response.data.errors) ||
                                                ['Unable to update user']
                                        return Promise.reject(errors)
                                }
                        },
                        async deactivateSubUser(_, { id } = { id: 0 }) {
                                try {
                                        await http.patch(`/sub_users/users/${id}/deactivate`)
                                } catch (e) {
                                        const errors = (e && e.response && e.response.data && e.response.data.errors) ||
                                                ['Unable to deactivate user']
                                        return Promise.reject(errors)
                                }
                        }
                }
        });


        nuxtApp.vueApp.use(store);
});
