export const useAuthorizedFetch = (request, opts) => {
        const headers = { Accept: "application/json" }
        const csrfToken = useCookie("CSRF-TOKEN").value
        if (csrfToken) {
                headers["X-CSRF-TOKEN"] = csrfToken
        }
        return $fetch(request, {
                baseURL: useRuntimeConfig().public.baseUrl,
                headers,
                credentials: "include",
                ...opts,
        })
}
