import { z } from "zod"
import { always, empty } from "@schtappe/utils"

export class Integer extends Number { }
export class Decimal extends Number { }
export class Encrypted extends String { }

export const INPUT_MAP = new Map([
        [Boolean, {
                parser: z.boolean,
                default: always(empty(Boolean)),
                input: "checkbox",
        }],
        [Date, {
                parser: z.coerce.date,
                default: always(empty(Date)),
                input: "text",
        }],
        [Decimal, {
                parser: z.coerce.string,
                default: always("0.0"),
                input: "number",
        }],
        [File, {
                parser: z.instanceof(File).nullable,
                default: always(null), // NOTE(aes): not empty(File) so that from will not submit it
                input: "file",
        }],
        [Integer, {
                parser: z.number,
                default: always(0),
                input: "number",
        }],
        [String, {
                parser: z.string,
                default: always(""),
                input: "text",
        }],
        [Encrypted, {
                parser: z.string,
                default: always(""),
                input: "password",
        }],
])
