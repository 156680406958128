import { z } from "zod"
import { empty, pick } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal, INPUT_MAP } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1 },
        name: { type: String },
        status: { type: String },
        ivr_tree: { type: String },
        account_id: { type: Integer, required: true, default: 1 },
        user_id: { type: Integer, required: true, default: 1 },
        rows: { type: Integer },
        layout: { type: String },
        variables: { type: String },
        caller_id: { type: String },
        non_globe_caller_id: { type: String },
        sms_masking: { type: String },
        voice: { type: String },
        using_random_caller: { type: Boolean },
        using_random_non_globe_caller: { type: Boolean },
        blast_at: { type: Date },
        created_at: { type: Date },
        updated_at: { type: Date },
}

const filters = [
        { key: "account", group: "select_model", option_key: "accounts" },
        { key: "user", group: "select_model", option_key: "users" },
        { key: "name", group: "text" },
        { key: "rows", group: "number" },
        { key: "caller", group: "text" },
        { key: "variables", group: "text" },
        { key: "using_random_caller", group: "select_boolean" },
        { key: "using_random_non_globe_caller", group: "select_boolean" },
        { key: "voice", group: "select_fixed", option_key: "voices", option_type: String },
        { key: "status", group: "select_fixed", option_key: "statuses", option_type: Number },
        { key: "blast_at", group: "date" },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
]

const Request = ({ helpers, config }) => ({
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                ...Shared.Request.Scoped(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
})

const Response = ({ helpers, config }) => ({
        FilterOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                users: z.tuple([z.string("name"), z.number("id")]).array(),
                voices: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        name: z.string(),
                        status: z.string(),
                        ivr_tree: z.string(),
                        rows: z.number(),
                        filename: z.string(),
                        account: z.object({
                                id: z.number(),
                                name: z.string(),
                        }),
                        user: z.object({
                                id: z.number(),
                                name: z.string(),
                        }),
                        voice: z.string(),
                        blast_at: z.coerce.date({ offset: true }),
                        variables: z.string(),
                        created_at: z.coerce.date({ offset: true }),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Count: z.object({
                all: z.number(),
                upcoming_campaigns: z.number(),
                ongoing_campaigns: z.number(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({ attributes: config.attributes }),
                account: z.string(),
                user: z.string(),
        }).safeParse,
})

const Api = ({ helpers, config }) => ({
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Count: {
                successParser: config.Response.Count,
        },
        Show: {
                successParser: config.Response.Show,
        },
})

// TODO(aes): make dynamic based on server
export const Scopes = () => Shared.Scopes([
        "all",
        "upcoming_campaigns",
        "ongoing_campaigns",
])

const TableHeaders = [
        { title: "Id", key: "id" },
        { title: "Name", key: "name" },
        { title: "Status", key: "status" },
        { title: "Ivr Tree", key: "ivr_tree" },
        { title: "Rows", key: "rows" },
        { title: "Filename", key: "filename" },
        {
                title: "Account",
                key: "account",
                getLink: ({ origin, item }) => item?.account?.id
                        ? (new URL(`/admin/accounts/${item.account.id}`, origin)).href
                        : "",
                format: ({ account }) => account?.name,
        },
        {
                title: "User",
                key: "user",
                getLink: ({ origin, item }) => item?.user?.id
                        ? (new URL(`/admin/users/${item.user.id}`, origin)).href
                        : "",
                format: ({ user }) => user?.name,
        },
        { title: "Voice", key: "voice" },
        { key: "blast_at", format: formatters.date },
        { title: "Variables", key: "variables" },
        { key: "created_at", format: formatters.date },
]

const Pages = {
        list: {
                isEditEnabled: false,
                actions: [
                        { name: "Outbound Call Transactions", link: ({ item }) => `/admin/campaigns/${item.id}/outbound_call_transactions` },
                ],
                breadcrumb: {
                        actions: [],
                },
        },
        show: {
                actions: [
                        { name: "Outbound Call Transactions", path: "outbound_call_transactions" },
                ],
                groups: [
                        {
                                name: "Campaign Details",
                                type: "property",
                                value: (resource) => {
                                        const result = pick([
                                                "name",
                                                "status",
                                                "ivr_tree",
                                                "account",
                                                "user",
                                                "rows",
                                                "layout",
                                                "variables",
                                                "caller_id",
                                                "non_globe_caller_id",
                                                "sms_masking",
                                                "voice",
                                                "using_random_caller",
                                                "using_random_non_globe_caller",
                                                "blast_at",
                                                "created_at",
                                                "updated_at",
                                        ], resource)

                                        result.account = { link: `/admin/accounts/${resource.account_id}`, value: result.account }
                                        result.user = { link: `/admin/users/${resource.user_id}`, value: result.user }

                                        return result
                                },
                        },
                ],
        },
}

export default Shared.defineAdminModel({
        key: "campaign",
        attributes,
        filters,
        Request,
        Response,
        Api,
        Scopes,
        TableHeaders,
        Pages,
})
