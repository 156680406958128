import { default as editBSwDDKlsXNMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexudLeX2qlznMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93H7CsmBa6uhMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as edit9Y4aIAiTHuMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexfhXFvYoI4xMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93LL32i1LhvLMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexm7FIG34ctqMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as new2QS7ESXyRhMeta } from "/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_planshfHB02cno8Meta } from "/codebuild/output/src1735812243/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editXl2etk7eLjMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexKl2DbFvL6BMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93S3uxCAHrbfMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexxGLhz6Lb8jMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newJ1oV6ofevxMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_planstLzOMsFb4EMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as edit6XQ925kexzMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexdGxcjhgREMMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_939za7w3YqFOMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexFctC8oAmNSMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as newxAL8oSJUgRMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idseAko3ZIjeUMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as editU7dMnrcHMeMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as indexphV7sX14VCMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as editaNeldLmtUyMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexUXEVJfAN2aMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93qKCzUJnH4GMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexXKe6s83Cv9Meta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newyX2ElCoBLCMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsaO2lzrjpoTMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93CFxHnJfGRyMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexZmoXLrcUk8Meta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionjmwyk5sx0CMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardMQG8vlXaZHMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accountsG1LvZX0SmoMeta } from "/codebuild/output/src1735812243/src/pages/admin/accounts.vue?macro=true";
import { default as editri68IRBHC8Meta } from "/codebuild/output/src1735812243/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexR1qx7gizF6Meta } from "/codebuild/output/src1735812243/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93WP38gNFs9BMeta } from "/codebuild/output/src1735812243/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as index4abM2rjcrbMeta } from "/codebuild/output/src1735812243/src/pages/admin/admin_users/index.vue?macro=true";
import { default as new7VUugB6dGQMeta } from "/codebuild/output/src1735812243/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_usersGlM6X2uuhyMeta } from "/codebuild/output/src1735812243/src/pages/admin/admin_users.vue?macro=true";
import { default as index9Vd21Nc50TMeta } from "/codebuild/output/src1735812243/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trailsolmxKP1pvNMeta } from "/codebuild/output/src1735812243/src/pages/admin/audit_trails.vue?macro=true";
import { default as editzKvOG6chHFMeta } from "/codebuild/output/src1735812243/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as indexuFGDUzgbyRMeta } from "/codebuild/output/src1735812243/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93FEyRsReaRaMeta } from "/codebuild/output/src1735812243/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indexF8ICGWYbWCMeta } from "/codebuild/output/src1735812243/src/pages/admin/billings/index.vue?macro=true";
import { default as newUgHgkNUrYVMeta } from "/codebuild/output/src1735812243/src/pages/admin/billings/new.vue?macro=true";
import { default as billingsQB5G6hi6MpMeta } from "/codebuild/output/src1735812243/src/pages/admin/billings.vue?macro=true";
import { default as editUDcsFMYnVlMeta } from "/codebuild/output/src1735812243/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexg8S4laV7wrMeta } from "/codebuild/output/src1735812243/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93dIrxbqAqGJMeta } from "/codebuild/output/src1735812243/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexH3X74FVdzcMeta } from "/codebuild/output/src1735812243/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newwBNBrvmTZdMeta } from "/codebuild/output/src1735812243/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_idsKQXHZeGpWfMeta } from "/codebuild/output/src1735812243/src/pages/admin/caller_ids.vue?macro=true";
import { default as edit5dxyNWm12VMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexQri7myI88mMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_93jZjnPvjersMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as edit4mUvAxF0uNMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as indextQdHtQnE8JMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as editTYBnVx9M4CMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexrSZWi0XVZGMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_933MlxLHaGpBMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as indexZqyK1LFKauMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as new2AQX7bzdGpMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactionseRGiOLKoaHMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_93pA78twOIgEMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as indexHn1e0mCm9aMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newd0dQroq3RtMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsKexNGJclbuMeta } from "/codebuild/output/src1735812243/src/pages/admin/campaigns.vue?macro=true";
import { default as indexhyAxImTCniMeta } from "/codebuild/output/src1735812243/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93hogrgFeBj9Meta } from "/codebuild/output/src1735812243/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexL6TED46WXKMeta } from "/codebuild/output/src1735812243/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as new5InFIVb79SMeta } from "/codebuild/output/src1735812243/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emails7CAATgVVpJMeta } from "/codebuild/output/src1735812243/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexpxzTbDrDBUMeta } from "/codebuild/output/src1735812243/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboardQFKMWeQy7dMeta } from "/codebuild/output/src1735812243/src/pages/admin/dashboard.vue?macro=true";
import { default as indexNRsSUFLtjjMeta } from "/codebuild/output/src1735812243/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93kq7SaJnnbrMeta } from "/codebuild/output/src1735812243/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexyqXHfnj6K4Meta } from "/codebuild/output/src1735812243/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newMO7dPEk25uMeta } from "/codebuild/output/src1735812243/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesKmwt9fBAAnMeta } from "/codebuild/output/src1735812243/src/pages/admin/inquiries.vue?macro=true";
import { default as indexuBCVPU4wVjMeta } from "/codebuild/output/src1735812243/src/pages/admin/login/index.vue?macro=true";
import { default as loginNW0CaYGa12Meta } from "/codebuild/output/src1735812243/src/pages/admin/login.vue?macro=true";
import { default as newEwdEMB8CitMeta } from "/codebuild/output/src1735812243/src/pages/admin/password/new.vue?macro=true";
import { default as index8CMGtDEkAeMeta } from "/codebuild/output/src1735812243/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsgLs7BKUqFvMeta } from "/codebuild/output/src1735812243/src/pages/admin/reports.vue?macro=true";
import { default as editz968mxdz8JMeta } from "/codebuild/output/src1735812243/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexllsFcIFCUXMeta } from "/codebuild/output/src1735812243/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93BPkYg0Z9HoMeta } from "/codebuild/output/src1735812243/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexgMS0dMH2h5Meta } from "/codebuild/output/src1735812243/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newX7dJ9iggNWMeta } from "/codebuild/output/src1735812243/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingslWKzSgm4GQMeta } from "/codebuild/output/src1735812243/src/pages/admin/sms_maskings.vue?macro=true";
import { default as editqAoivpDf0xMeta } from "/codebuild/output/src1735812243/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexMq6xS51WjkMeta } from "/codebuild/output/src1735812243/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_93bX6sPD808zMeta } from "/codebuild/output/src1735812243/src/pages/admin/users/[user_id].vue?macro=true";
import { default as indexQROLKej8eUMeta } from "/codebuild/output/src1735812243/src/pages/admin/users/index.vue?macro=true";
import { default as newlUxUn6TIksMeta } from "/codebuild/output/src1735812243/src/pages/admin/users/new.vue?macro=true";
import { default as usersgN1STlTZWtMeta } from "/codebuild/output/src1735812243/src/pages/admin/users.vue?macro=true";
import { default as editsP1RBVSXssMeta } from "/codebuild/output/src1735812243/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as index8hQnwXhjwQMeta } from "/codebuild/output/src1735812243/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_93sxNf5GQzLVMeta } from "/codebuild/output/src1735812243/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as adminsZUxsGryWoMeta } from "/codebuild/output/src1735812243/src/pages/admin.vue?macro=true";
import { default as indexIO47jlU5fAMeta } from "/codebuild/output/src1735812243/src/pages/audit_logs/index.vue?macro=true";
import { default as indexqXayPk8asIMeta } from "/codebuild/output/src1735812243/src/pages/billings/index.vue?macro=true";
import { default as indexMFRr0MQg3PMeta } from "/codebuild/output/src1735812243/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_937AGWYjfhT8Meta } from "/codebuild/output/src1735812243/src/pages/call_recordings/[id].vue?macro=true";
import { default as index9Ygnd4ASWDMeta } from "/codebuild/output/src1735812243/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as index3zaEmhrGGSMeta } from "/codebuild/output/src1735812243/src/pages/campaigns/index.vue?macro=true";
import { default as indexYtp6pL7ph3Meta } from "/codebuild/output/src1735812243/src/pages/dashboard/index.vue?macro=true";
import { default as indexPgZbNJld41Meta } from "/codebuild/output/src1735812243/src/pages/help/index.vue?macro=true";
import { default as indexZ3mbPM1vCzMeta } from "/codebuild/output/src1735812243/src/pages/index.vue?macro=true";
import { default as indexJe4hgMbCTdMeta } from "/codebuild/output/src1735812243/src/pages/inquiry/index.vue?macro=true";
import { default as editorT6WuEHEuUlMeta } from "/codebuild/output/src1735812243/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexWh78qzjqU5Meta } from "/codebuild/output/src1735812243/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_email8CgUEPw5evMeta } from "/codebuild/output/src1735812243/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardimjcBqQ6vhMeta } from "/codebuild/output/src1735812243/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexEIAEpPFmH5Meta } from "/codebuild/output/src1735812243/src/pages/profiles/index.vue?macro=true";
import { default as indexZuAMsz8gsKMeta } from "/codebuild/output/src1735812243/src/pages/sub_users/users/index.vue?macro=true";
import { default as indexoTp3EyoX78Meta } from "/codebuild/output/src1735812243/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexOjyJcTlSBbMeta } from "/codebuild/output/src1735812243/src/pages/users/password/edit/index.vue?macro=true";
import { default as index9mtajvokECMeta } from "/codebuild/output/src1735812243/src/pages/users/password/new/index.vue?macro=true";
import { default as indexrs6lyVtSefMeta } from "/codebuild/output/src1735812243/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: adminsZUxsGryWoMeta?.name ?? "admin",
    path: adminsZUxsGryWoMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93H7CsmBa6uhMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editBSwDDKlsXNMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editBSwDDKlsXNMeta?.path ?? "edit",
    meta: editBSwDDKlsXNMeta || {},
    alias: editBSwDDKlsXNMeta?.alias || [],
    redirect: editBSwDDKlsXNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexudLeX2qlznMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexudLeX2qlznMeta?.path ?? "",
    meta: indexudLeX2qlznMeta || {},
    alias: indexudLeX2qlznMeta?.alias || [],
    redirect: indexudLeX2qlznMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93H7CsmBa6uhMeta?.name ?? undefined,
    meta: _91account_plan_id_93H7CsmBa6uhMeta || {},
    alias: _91account_plan_id_93H7CsmBa6uhMeta?.alias || [],
    redirect: _91account_plan_id_93H7CsmBa6uhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_planshfHB02cno8Meta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93LL32i1LhvLMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: edit9Y4aIAiTHuMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: edit9Y4aIAiTHuMeta?.path ?? "edit",
    meta: edit9Y4aIAiTHuMeta || {},
    alias: edit9Y4aIAiTHuMeta?.alias || [],
    redirect: edit9Y4aIAiTHuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfhXFvYoI4xMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: indexfhXFvYoI4xMeta?.path ?? "",
    meta: indexfhXFvYoI4xMeta || {},
    alias: indexfhXFvYoI4xMeta?.alias || [],
    redirect: indexfhXFvYoI4xMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93LL32i1LhvLMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93LL32i1LhvLMeta || {},
    alias: _91account_requested_plan_id_93LL32i1LhvLMeta?.alias || [],
    redirect: _91account_requested_plan_id_93LL32i1LhvLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexm7FIG34ctqMeta?.name ?? "admin-account_requested_plans",
    path: indexm7FIG34ctqMeta?.path ?? "",
    meta: indexm7FIG34ctqMeta || {},
    alias: indexm7FIG34ctqMeta?.alias || [],
    redirect: indexm7FIG34ctqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: new2QS7ESXyRhMeta?.name ?? "admin-account_requested_plans-new",
    path: new2QS7ESXyRhMeta?.path ?? "new",
    meta: new2QS7ESXyRhMeta || {},
    alias: new2QS7ESXyRhMeta?.alias || [],
    redirect: new2QS7ESXyRhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_planshfHB02cno8Meta?.name ?? undefined,
    meta: account_requested_planshfHB02cno8Meta || {},
    alias: account_requested_planshfHB02cno8Meta?.alias || [],
    redirect: account_requested_planshfHB02cno8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accountsG1LvZX0SmoMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93CFxHnJfGRyMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_planstLzOMsFb4EMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93S3uxCAHrbfMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editXl2etk7eLjMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editXl2etk7eLjMeta?.path ?? "edit",
    meta: editXl2etk7eLjMeta || {},
    alias: editXl2etk7eLjMeta?.alias || [],
    redirect: editXl2etk7eLjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKl2DbFvL6BMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indexKl2DbFvL6BMeta?.path ?? "",
    meta: indexKl2DbFvL6BMeta || {},
    alias: indexKl2DbFvL6BMeta?.alias || [],
    redirect: indexKl2DbFvL6BMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93S3uxCAHrbfMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93S3uxCAHrbfMeta || {},
    alias: _91account_requested_plan_id_93S3uxCAHrbfMeta?.alias || [],
    redirect: _91account_requested_plan_id_93S3uxCAHrbfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexxGLhz6Lb8jMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: indexxGLhz6Lb8jMeta?.path ?? "",
    meta: indexxGLhz6Lb8jMeta || {},
    alias: indexxGLhz6Lb8jMeta?.alias || [],
    redirect: indexxGLhz6Lb8jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newJ1oV6ofevxMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newJ1oV6ofevxMeta?.path ?? "new",
    meta: newJ1oV6ofevxMeta || {},
    alias: newJ1oV6ofevxMeta?.alias || [],
    redirect: newJ1oV6ofevxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_planstLzOMsFb4EMeta?.name ?? undefined,
    meta: account_requested_planstLzOMsFb4EMeta || {},
    alias: account_requested_planstLzOMsFb4EMeta?.alias || [],
    redirect: account_requested_planstLzOMsFb4EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idseAko3ZIjeUMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_939za7w3YqFOMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: edit6XQ925kexzMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: edit6XQ925kexzMeta?.path ?? "edit",
    meta: edit6XQ925kexzMeta || {},
    alias: edit6XQ925kexzMeta?.alias || [],
    redirect: edit6XQ925kexzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexdGxcjhgREMMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexdGxcjhgREMMeta?.path ?? "",
    meta: indexdGxcjhgREMMeta || {},
    alias: indexdGxcjhgREMMeta?.alias || [],
    redirect: indexdGxcjhgREMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_939za7w3YqFOMeta?.name ?? undefined,
    meta: _91caller_id_id_939za7w3YqFOMeta || {},
    alias: _91caller_id_id_939za7w3YqFOMeta?.alias || [],
    redirect: _91caller_id_id_939za7w3YqFOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexFctC8oAmNSMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: indexFctC8oAmNSMeta?.path ?? "",
    meta: indexFctC8oAmNSMeta || {},
    alias: indexFctC8oAmNSMeta?.alias || [],
    redirect: indexFctC8oAmNSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newxAL8oSJUgRMeta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: newxAL8oSJUgRMeta?.path ?? "new",
    meta: newxAL8oSJUgRMeta || {},
    alias: newxAL8oSJUgRMeta?.alias || [],
    redirect: newxAL8oSJUgRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idseAko3ZIjeUMeta?.name ?? undefined,
    meta: caller_idseAko3ZIjeUMeta || {},
    alias: caller_idseAko3ZIjeUMeta?.alias || [],
    redirect: caller_idseAko3ZIjeUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: editU7dMnrcHMeMeta?.name ?? "admin-accounts-account_id-edit",
    path: editU7dMnrcHMeMeta?.path ?? "edit",
    meta: editU7dMnrcHMeMeta || {},
    alias: editU7dMnrcHMeMeta?.alias || [],
    redirect: editU7dMnrcHMeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexphV7sX14VCMeta?.name ?? "admin-accounts-account_id",
    path: indexphV7sX14VCMeta?.path ?? "",
    meta: indexphV7sX14VCMeta || {},
    alias: indexphV7sX14VCMeta?.alias || [],
    redirect: indexphV7sX14VCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsaO2lzrjpoTMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93qKCzUJnH4GMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editaNeldLmtUyMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: editaNeldLmtUyMeta?.path ?? "edit",
    meta: editaNeldLmtUyMeta || {},
    alias: editaNeldLmtUyMeta?.alias || [],
    redirect: editaNeldLmtUyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexUXEVJfAN2aMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexUXEVJfAN2aMeta?.path ?? "",
    meta: indexUXEVJfAN2aMeta || {},
    alias: indexUXEVJfAN2aMeta?.alias || [],
    redirect: indexUXEVJfAN2aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93qKCzUJnH4GMeta?.name ?? undefined,
    meta: _91sms_masking_id_93qKCzUJnH4GMeta || {},
    alias: _91sms_masking_id_93qKCzUJnH4GMeta?.alias || [],
    redirect: _91sms_masking_id_93qKCzUJnH4GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexXKe6s83Cv9Meta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexXKe6s83Cv9Meta?.path ?? "",
    meta: indexXKe6s83Cv9Meta || {},
    alias: indexXKe6s83Cv9Meta?.alias || [],
    redirect: indexXKe6s83Cv9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newyX2ElCoBLCMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newyX2ElCoBLCMeta?.path ?? "new",
    meta: newyX2ElCoBLCMeta || {},
    alias: newyX2ElCoBLCMeta?.alias || [],
    redirect: newyX2ElCoBLCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsaO2lzrjpoTMeta?.name ?? undefined,
    meta: sms_maskingsaO2lzrjpoTMeta || {},
    alias: sms_maskingsaO2lzrjpoTMeta?.alias || [],
    redirect: sms_maskingsaO2lzrjpoTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93CFxHnJfGRyMeta?.name ?? undefined,
    meta: _91account_id_93CFxHnJfGRyMeta || {},
    alias: _91account_id_93CFxHnJfGRyMeta?.alias || [],
    redirect: _91account_id_93CFxHnJfGRyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexZmoXLrcUk8Meta?.name ?? "admin-accounts",
    path: indexZmoXLrcUk8Meta?.path ?? "",
    meta: indexZmoXLrcUk8Meta || {},
    alias: indexZmoXLrcUk8Meta?.alias || [],
    redirect: indexZmoXLrcUk8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionjmwyk5sx0CMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionjmwyk5sx0CMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionjmwyk5sx0CMeta || {},
    alias: wizard_plan_selectionjmwyk5sx0CMeta?.alias || [],
    redirect: wizard_plan_selectionjmwyk5sx0CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardMQG8vlXaZHMeta?.name ?? "admin-accounts-wizard",
    path: wizardMQG8vlXaZHMeta?.path ?? "wizard",
    meta: wizardMQG8vlXaZHMeta || {},
    alias: wizardMQG8vlXaZHMeta?.alias || [],
    redirect: wizardMQG8vlXaZHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accountsG1LvZX0SmoMeta?.name ?? undefined,
    meta: accountsG1LvZX0SmoMeta || {},
    alias: accountsG1LvZX0SmoMeta?.alias || [],
    redirect: accountsG1LvZX0SmoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_usersGlM6X2uuhyMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93WP38gNFs9BMeta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editri68IRBHC8Meta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editri68IRBHC8Meta?.path ?? "edit",
    meta: editri68IRBHC8Meta || {},
    alias: editri68IRBHC8Meta?.alias || [],
    redirect: editri68IRBHC8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexR1qx7gizF6Meta?.name ?? "admin-admin_users-admin_user_id",
    path: indexR1qx7gizF6Meta?.path ?? "",
    meta: indexR1qx7gizF6Meta || {},
    alias: indexR1qx7gizF6Meta?.alias || [],
    redirect: indexR1qx7gizF6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93WP38gNFs9BMeta?.name ?? undefined,
    meta: _91admin_user_id_93WP38gNFs9BMeta || {},
    alias: _91admin_user_id_93WP38gNFs9BMeta?.alias || [],
    redirect: _91admin_user_id_93WP38gNFs9BMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: index4abM2rjcrbMeta?.name ?? "admin-admin_users",
    path: index4abM2rjcrbMeta?.path ?? "",
    meta: index4abM2rjcrbMeta || {},
    alias: index4abM2rjcrbMeta?.alias || [],
    redirect: index4abM2rjcrbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: new7VUugB6dGQMeta?.name ?? "admin-admin_users-new",
    path: new7VUugB6dGQMeta?.path ?? "new",
    meta: new7VUugB6dGQMeta || {},
    alias: new7VUugB6dGQMeta?.alias || [],
    redirect: new7VUugB6dGQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_usersGlM6X2uuhyMeta?.name ?? undefined,
    meta: admin_usersGlM6X2uuhyMeta || {},
    alias: admin_usersGlM6X2uuhyMeta?.alias || [],
    redirect: admin_usersGlM6X2uuhyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trailsolmxKP1pvNMeta?.path ?? "audit_trails",
    children: [
  {
    name: index9Vd21Nc50TMeta?.name ?? "admin-audit_trails",
    path: index9Vd21Nc50TMeta?.path ?? "",
    meta: index9Vd21Nc50TMeta || {},
    alias: index9Vd21Nc50TMeta?.alias || [],
    redirect: index9Vd21Nc50TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trailsolmxKP1pvNMeta?.name ?? undefined,
    meta: audit_trailsolmxKP1pvNMeta || {},
    alias: audit_trailsolmxKP1pvNMeta?.alias || [],
    redirect: audit_trailsolmxKP1pvNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingsQB5G6hi6MpMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93FEyRsReaRaMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editzKvOG6chHFMeta?.name ?? "admin-billings-billing_id-edit",
    path: editzKvOG6chHFMeta?.path ?? "edit",
    meta: editzKvOG6chHFMeta || {},
    alias: editzKvOG6chHFMeta?.alias || [],
    redirect: editzKvOG6chHFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuFGDUzgbyRMeta?.name ?? "admin-billings-billing_id",
    path: indexuFGDUzgbyRMeta?.path ?? "",
    meta: indexuFGDUzgbyRMeta || {},
    alias: indexuFGDUzgbyRMeta?.alias || [],
    redirect: indexuFGDUzgbyRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93FEyRsReaRaMeta?.name ?? undefined,
    meta: _91billing_id_93FEyRsReaRaMeta || {},
    alias: _91billing_id_93FEyRsReaRaMeta?.alias || [],
    redirect: _91billing_id_93FEyRsReaRaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indexF8ICGWYbWCMeta?.name ?? "admin-billings",
    path: indexF8ICGWYbWCMeta?.path ?? "",
    meta: indexF8ICGWYbWCMeta || {},
    alias: indexF8ICGWYbWCMeta?.alias || [],
    redirect: indexF8ICGWYbWCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newUgHgkNUrYVMeta?.name ?? "admin-billings-new",
    path: newUgHgkNUrYVMeta?.path ?? "new",
    meta: newUgHgkNUrYVMeta || {},
    alias: newUgHgkNUrYVMeta?.alias || [],
    redirect: newUgHgkNUrYVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingsQB5G6hi6MpMeta?.name ?? undefined,
    meta: billingsQB5G6hi6MpMeta || {},
    alias: billingsQB5G6hi6MpMeta?.alias || [],
    redirect: billingsQB5G6hi6MpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_idsKQXHZeGpWfMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93dIrxbqAqGJMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editUDcsFMYnVlMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: editUDcsFMYnVlMeta?.path ?? "edit",
    meta: editUDcsFMYnVlMeta || {},
    alias: editUDcsFMYnVlMeta?.alias || [],
    redirect: editUDcsFMYnVlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexg8S4laV7wrMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexg8S4laV7wrMeta?.path ?? "",
    meta: indexg8S4laV7wrMeta || {},
    alias: indexg8S4laV7wrMeta?.alias || [],
    redirect: indexg8S4laV7wrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93dIrxbqAqGJMeta?.name ?? undefined,
    meta: _91caller_id_id_93dIrxbqAqGJMeta || {},
    alias: _91caller_id_id_93dIrxbqAqGJMeta?.alias || [],
    redirect: _91caller_id_id_93dIrxbqAqGJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexH3X74FVdzcMeta?.name ?? "admin-caller_ids",
    path: indexH3X74FVdzcMeta?.path ?? "",
    meta: indexH3X74FVdzcMeta || {},
    alias: indexH3X74FVdzcMeta?.alias || [],
    redirect: indexH3X74FVdzcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newwBNBrvmTZdMeta?.name ?? "admin-caller_ids-new",
    path: newwBNBrvmTZdMeta?.path ?? "new",
    meta: newwBNBrvmTZdMeta || {},
    alias: newwBNBrvmTZdMeta?.alias || [],
    redirect: newwBNBrvmTZdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsKQXHZeGpWfMeta?.name ?? undefined,
    meta: caller_idsKQXHZeGpWfMeta || {},
    alias: caller_idsKQXHZeGpWfMeta?.alias || [],
    redirect: caller_idsKQXHZeGpWfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_93jZjnPvjersMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: edit5dxyNWm12VMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: edit5dxyNWm12VMeta?.path ?? "edit",
    meta: edit5dxyNWm12VMeta || {},
    alias: edit5dxyNWm12VMeta?.alias || [],
    redirect: edit5dxyNWm12VMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQri7myI88mMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexQri7myI88mMeta?.path ?? "",
    meta: indexQri7myI88mMeta || {},
    alias: indexQri7myI88mMeta?.alias || [],
    redirect: indexQri7myI88mMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_93jZjnPvjersMeta?.name ?? undefined,
    meta: _91campaign_setting_id_93jZjnPvjersMeta || {},
    alias: _91campaign_setting_id_93jZjnPvjersMeta?.alias || [],
    redirect: _91campaign_setting_id_93jZjnPvjersMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsKexNGJclbuMeta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_93pA78twOIgEMeta?.path ?? ":campaign_id()",
    children: [
  {
    name: edit4mUvAxF0uNMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: edit4mUvAxF0uNMeta?.path ?? "edit",
    meta: edit4mUvAxF0uNMeta || {},
    alias: edit4mUvAxF0uNMeta?.alias || [],
    redirect: edit4mUvAxF0uNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indextQdHtQnE8JMeta?.name ?? "admin-campaigns-campaign_id",
    path: indextQdHtQnE8JMeta?.path ?? "",
    meta: indextQdHtQnE8JMeta || {},
    alias: indextQdHtQnE8JMeta?.alias || [],
    redirect: indextQdHtQnE8JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactionseRGiOLKoaHMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_933MlxLHaGpBMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: editTYBnVx9M4CMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: editTYBnVx9M4CMeta?.path ?? "edit",
    meta: editTYBnVx9M4CMeta || {},
    alias: editTYBnVx9M4CMeta?.alias || [],
    redirect: editTYBnVx9M4CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexrSZWi0XVZGMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexrSZWi0XVZGMeta?.path ?? "",
    meta: indexrSZWi0XVZGMeta || {},
    alias: indexrSZWi0XVZGMeta?.alias || [],
    redirect: indexrSZWi0XVZGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_933MlxLHaGpBMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_933MlxLHaGpBMeta || {},
    alias: _91outbound_call_transaction_id_933MlxLHaGpBMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_933MlxLHaGpBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexZqyK1LFKauMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: indexZqyK1LFKauMeta?.path ?? "",
    meta: indexZqyK1LFKauMeta || {},
    alias: indexZqyK1LFKauMeta?.alias || [],
    redirect: indexZqyK1LFKauMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: new2AQX7bzdGpMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: new2AQX7bzdGpMeta?.path ?? "new",
    meta: new2AQX7bzdGpMeta || {},
    alias: new2AQX7bzdGpMeta?.alias || [],
    redirect: new2AQX7bzdGpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactionseRGiOLKoaHMeta?.name ?? undefined,
    meta: outbound_call_transactionseRGiOLKoaHMeta || {},
    alias: outbound_call_transactionseRGiOLKoaHMeta?.alias || [],
    redirect: outbound_call_transactionseRGiOLKoaHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_93pA78twOIgEMeta?.name ?? undefined,
    meta: _91campaign_id_93pA78twOIgEMeta || {},
    alias: _91campaign_id_93pA78twOIgEMeta?.alias || [],
    redirect: _91campaign_id_93pA78twOIgEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: indexHn1e0mCm9aMeta?.name ?? "admin-campaigns",
    path: indexHn1e0mCm9aMeta?.path ?? "",
    meta: indexHn1e0mCm9aMeta || {},
    alias: indexHn1e0mCm9aMeta?.alias || [],
    redirect: indexHn1e0mCm9aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newd0dQroq3RtMeta?.name ?? "admin-campaigns-new",
    path: newd0dQroq3RtMeta?.path ?? "new",
    meta: newd0dQroq3RtMeta || {},
    alias: newd0dQroq3RtMeta?.alias || [],
    redirect: newd0dQroq3RtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsKexNGJclbuMeta?.name ?? undefined,
    meta: campaignsKexNGJclbuMeta || {},
    alias: campaignsKexNGJclbuMeta?.alias || [],
    redirect: campaignsKexNGJclbuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emails7CAATgVVpJMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93hogrgFeBj9Meta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexhyAxImTCniMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexhyAxImTCniMeta?.path ?? "",
    meta: indexhyAxImTCniMeta || {},
    alias: indexhyAxImTCniMeta?.alias || [],
    redirect: indexhyAxImTCniMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93hogrgFeBj9Meta?.name ?? undefined,
    meta: _91custom_email_id_93hogrgFeBj9Meta || {},
    alias: _91custom_email_id_93hogrgFeBj9Meta?.alias || [],
    redirect: _91custom_email_id_93hogrgFeBj9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexL6TED46WXKMeta?.name ?? "admin-custom_emails",
    path: indexL6TED46WXKMeta?.path ?? "",
    meta: indexL6TED46WXKMeta || {},
    alias: indexL6TED46WXKMeta?.alias || [],
    redirect: indexL6TED46WXKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: new5InFIVb79SMeta?.name ?? "admin-custom_emails-new",
    path: new5InFIVb79SMeta?.path ?? "new",
    meta: new5InFIVb79SMeta || {},
    alias: new5InFIVb79SMeta?.alias || [],
    redirect: new5InFIVb79SMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emails7CAATgVVpJMeta?.name ?? undefined,
    meta: custom_emails7CAATgVVpJMeta || {},
    alias: custom_emails7CAATgVVpJMeta?.alias || [],
    redirect: custom_emails7CAATgVVpJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboardQFKMWeQy7dMeta?.path ?? "dashboard",
    children: [
  {
    name: indexpxzTbDrDBUMeta?.name ?? "admin-dashboard",
    path: indexpxzTbDrDBUMeta?.path ?? "",
    meta: indexpxzTbDrDBUMeta || {},
    alias: indexpxzTbDrDBUMeta?.alias || [],
    redirect: indexpxzTbDrDBUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardQFKMWeQy7dMeta?.name ?? undefined,
    meta: dashboardQFKMWeQy7dMeta || {},
    alias: dashboardQFKMWeQy7dMeta?.alias || [],
    redirect: dashboardQFKMWeQy7dMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesKmwt9fBAAnMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93kq7SaJnnbrMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: indexNRsSUFLtjjMeta?.name ?? "admin-inquiries-inquiry_id",
    path: indexNRsSUFLtjjMeta?.path ?? "",
    meta: indexNRsSUFLtjjMeta || {},
    alias: indexNRsSUFLtjjMeta?.alias || [],
    redirect: indexNRsSUFLtjjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93kq7SaJnnbrMeta?.name ?? undefined,
    meta: _91inquiry_id_93kq7SaJnnbrMeta || {},
    alias: _91inquiry_id_93kq7SaJnnbrMeta?.alias || [],
    redirect: _91inquiry_id_93kq7SaJnnbrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexyqXHfnj6K4Meta?.name ?? "admin-inquiries",
    path: indexyqXHfnj6K4Meta?.path ?? "",
    meta: indexyqXHfnj6K4Meta || {},
    alias: indexyqXHfnj6K4Meta?.alias || [],
    redirect: indexyqXHfnj6K4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newMO7dPEk25uMeta?.name ?? "admin-inquiries-new",
    path: newMO7dPEk25uMeta?.path ?? "new",
    meta: newMO7dPEk25uMeta || {},
    alias: newMO7dPEk25uMeta?.alias || [],
    redirect: newMO7dPEk25uMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesKmwt9fBAAnMeta?.name ?? undefined,
    meta: inquiriesKmwt9fBAAnMeta || {},
    alias: inquiriesKmwt9fBAAnMeta?.alias || [],
    redirect: inquiriesKmwt9fBAAnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginNW0CaYGa12Meta?.path ?? "login",
    children: [
  {
    name: indexuBCVPU4wVjMeta?.name ?? "admin-login",
    path: indexuBCVPU4wVjMeta?.path ?? "",
    meta: indexuBCVPU4wVjMeta || {},
    alias: indexuBCVPU4wVjMeta?.alias || [],
    redirect: indexuBCVPU4wVjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginNW0CaYGa12Meta?.name ?? undefined,
    meta: loginNW0CaYGa12Meta || {},
    alias: loginNW0CaYGa12Meta?.alias || [],
    redirect: loginNW0CaYGa12Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: newEwdEMB8CitMeta?.name ?? "admin-password-new",
    path: newEwdEMB8CitMeta?.path ?? "password/new",
    meta: newEwdEMB8CitMeta || {},
    alias: newEwdEMB8CitMeta?.alias || [],
    redirect: newEwdEMB8CitMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/password/new.vue").then(m => m.default || m)
  },
  {
    path: reportsgLs7BKUqFvMeta?.path ?? "reports",
    children: [
  {
    name: index8CMGtDEkAeMeta?.name ?? "admin-reports",
    path: index8CMGtDEkAeMeta?.path ?? "",
    meta: index8CMGtDEkAeMeta || {},
    alias: index8CMGtDEkAeMeta?.alias || [],
    redirect: index8CMGtDEkAeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsgLs7BKUqFvMeta?.name ?? undefined,
    meta: reportsgLs7BKUqFvMeta || {},
    alias: reportsgLs7BKUqFvMeta?.alias || [],
    redirect: reportsgLs7BKUqFvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingslWKzSgm4GQMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93BPkYg0Z9HoMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: editz968mxdz8JMeta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: editz968mxdz8JMeta?.path ?? "edit",
    meta: editz968mxdz8JMeta || {},
    alias: editz968mxdz8JMeta?.alias || [],
    redirect: editz968mxdz8JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexllsFcIFCUXMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexllsFcIFCUXMeta?.path ?? "",
    meta: indexllsFcIFCUXMeta || {},
    alias: indexllsFcIFCUXMeta?.alias || [],
    redirect: indexllsFcIFCUXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93BPkYg0Z9HoMeta?.name ?? undefined,
    meta: _91sms_masking_id_93BPkYg0Z9HoMeta || {},
    alias: _91sms_masking_id_93BPkYg0Z9HoMeta?.alias || [],
    redirect: _91sms_masking_id_93BPkYg0Z9HoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexgMS0dMH2h5Meta?.name ?? "admin-sms_maskings",
    path: indexgMS0dMH2h5Meta?.path ?? "",
    meta: indexgMS0dMH2h5Meta || {},
    alias: indexgMS0dMH2h5Meta?.alias || [],
    redirect: indexgMS0dMH2h5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newX7dJ9iggNWMeta?.name ?? "admin-sms_maskings-new",
    path: newX7dJ9iggNWMeta?.path ?? "new",
    meta: newX7dJ9iggNWMeta || {},
    alias: newX7dJ9iggNWMeta?.alias || [],
    redirect: newX7dJ9iggNWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingslWKzSgm4GQMeta?.name ?? undefined,
    meta: sms_maskingslWKzSgm4GQMeta || {},
    alias: sms_maskingslWKzSgm4GQMeta?.alias || [],
    redirect: sms_maskingslWKzSgm4GQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: usersgN1STlTZWtMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_93bX6sPD808zMeta?.path ?? ":user_id()",
    children: [
  {
    name: editqAoivpDf0xMeta?.name ?? "admin-users-user_id-edit",
    path: editqAoivpDf0xMeta?.path ?? "edit",
    meta: editqAoivpDf0xMeta || {},
    alias: editqAoivpDf0xMeta?.alias || [],
    redirect: editqAoivpDf0xMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMq6xS51WjkMeta?.name ?? "admin-users-user_id",
    path: indexMq6xS51WjkMeta?.path ?? "",
    meta: indexMq6xS51WjkMeta || {},
    alias: indexMq6xS51WjkMeta?.alias || [],
    redirect: indexMq6xS51WjkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_93bX6sPD808zMeta?.name ?? undefined,
    meta: _91user_id_93bX6sPD808zMeta || {},
    alias: _91user_id_93bX6sPD808zMeta?.alias || [],
    redirect: _91user_id_93bX6sPD808zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexQROLKej8eUMeta?.name ?? "admin-users",
    path: indexQROLKej8eUMeta?.path ?? "",
    meta: indexQROLKej8eUMeta || {},
    alias: indexQROLKej8eUMeta?.alias || [],
    redirect: indexQROLKej8eUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newlUxUn6TIksMeta?.name ?? "admin-users-new",
    path: newlUxUn6TIksMeta?.path ?? "new",
    meta: newlUxUn6TIksMeta || {},
    alias: newlUxUn6TIksMeta?.alias || [],
    redirect: newlUxUn6TIksMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: usersgN1STlTZWtMeta?.name ?? undefined,
    meta: usersgN1STlTZWtMeta || {},
    alias: usersgN1STlTZWtMeta?.alias || [],
    redirect: usersgN1STlTZWtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_93sxNf5GQzLVMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editsP1RBVSXssMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editsP1RBVSXssMeta?.path ?? "edit",
    meta: editsP1RBVSXssMeta || {},
    alias: editsP1RBVSXssMeta?.alias || [],
    redirect: editsP1RBVSXssMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index8hQnwXhjwQMeta?.name ?? "admin-wallets-wallet_id",
    path: index8hQnwXhjwQMeta?.path ?? "",
    meta: index8hQnwXhjwQMeta || {},
    alias: index8hQnwXhjwQMeta?.alias || [],
    redirect: index8hQnwXhjwQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_93sxNf5GQzLVMeta?.name ?? undefined,
    meta: _91wallet_id_93sxNf5GQzLVMeta || {},
    alias: _91wallet_id_93sxNf5GQzLVMeta?.alias || [],
    redirect: _91wallet_id_93sxNf5GQzLVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: adminsZUxsGryWoMeta || {},
    alias: adminsZUxsGryWoMeta?.alias || [],
    redirect: adminsZUxsGryWoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexIO47jlU5fAMeta?.name ?? "audit_logs",
    path: indexIO47jlU5fAMeta?.path ?? "/audit_logs",
    meta: indexIO47jlU5fAMeta || {},
    alias: indexIO47jlU5fAMeta?.alias || [],
    redirect: indexIO47jlU5fAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexqXayPk8asIMeta?.name ?? "billings",
    path: indexqXayPk8asIMeta?.path ?? "/billings",
    meta: indexqXayPk8asIMeta || {},
    alias: indexqXayPk8asIMeta?.alias || [],
    redirect: indexqXayPk8asIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFRr0MQg3PMeta?.name ?? "call_out-reports",
    path: indexMFRr0MQg3PMeta?.path ?? "/call_out/reports",
    meta: indexMFRr0MQg3PMeta || {},
    alias: indexMFRr0MQg3PMeta?.alias || [],
    redirect: indexMFRr0MQg3PMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937AGWYjfhT8Meta?.name ?? "call_recordings-id",
    path: _91id_937AGWYjfhT8Meta?.path ?? "/call_recordings/:id()",
    meta: _91id_937AGWYjfhT8Meta || {},
    alias: _91id_937AGWYjfhT8Meta?.alias || [],
    redirect: _91id_937AGWYjfhT8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: index9Ygnd4ASWDMeta?.name ?? "campaigns-id",
    path: index9Ygnd4ASWDMeta?.path ?? "/campaigns/:id()",
    meta: index9Ygnd4ASWDMeta || {},
    alias: index9Ygnd4ASWDMeta?.alias || [],
    redirect: index9Ygnd4ASWDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3zaEmhrGGSMeta?.name ?? "campaigns",
    path: index3zaEmhrGGSMeta?.path ?? "/campaigns",
    meta: index3zaEmhrGGSMeta || {},
    alias: index3zaEmhrGGSMeta?.alias || [],
    redirect: index3zaEmhrGGSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexYtp6pL7ph3Meta?.name ?? "dashboard",
    path: indexYtp6pL7ph3Meta?.path ?? "/dashboard",
    meta: indexYtp6pL7ph3Meta || {},
    alias: indexYtp6pL7ph3Meta?.alias || [],
    redirect: indexYtp6pL7ph3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexPgZbNJld41Meta?.name ?? "help",
    path: indexPgZbNJld41Meta?.path ?? "/help",
    meta: indexPgZbNJld41Meta || {},
    alias: indexPgZbNJld41Meta?.alias || [],
    redirect: indexPgZbNJld41Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ3mbPM1vCzMeta?.name ?? "index",
    path: indexZ3mbPM1vCzMeta?.path ?? "/",
    meta: indexZ3mbPM1vCzMeta || {},
    alias: indexZ3mbPM1vCzMeta?.alias || [],
    redirect: indexZ3mbPM1vCzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJe4hgMbCTdMeta?.name ?? "inquiry",
    path: indexJe4hgMbCTdMeta?.path ?? "/inquiry",
    meta: indexJe4hgMbCTdMeta || {},
    alias: indexJe4hgMbCTdMeta?.alias || [],
    redirect: indexJe4hgMbCTdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editorT6WuEHEuUlMeta?.name ?? "ivr_trees-id-editor",
    path: editorT6WuEHEuUlMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editorT6WuEHEuUlMeta || {},
    alias: editorT6WuEHEuUlMeta?.alias || [],
    redirect: editorT6WuEHEuUlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexWh78qzjqU5Meta?.name ?? "ivr_trees",
    path: indexWh78qzjqU5Meta?.path ?? "/ivr_trees",
    meta: indexWh78qzjqU5Meta || {},
    alias: indexWh78qzjqU5Meta?.alias || [],
    redirect: indexWh78qzjqU5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_email8CgUEPw5evMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_email8CgUEPw5evMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_email8CgUEPw5evMeta || {},
    alias: verify_email8CgUEPw5evMeta?.alias || [],
    redirect: verify_email8CgUEPw5evMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardimjcBqQ6vhMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardimjcBqQ6vhMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardimjcBqQ6vhMeta || {},
    alias: welcome_dashboardimjcBqQ6vhMeta?.alias || [],
    redirect: welcome_dashboardimjcBqQ6vhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexEIAEpPFmH5Meta?.name ?? "profiles",
    path: indexEIAEpPFmH5Meta?.path ?? "/profiles",
    meta: indexEIAEpPFmH5Meta || {},
    alias: indexEIAEpPFmH5Meta?.alias || [],
    redirect: indexEIAEpPFmH5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: indexZuAMsz8gsKMeta?.name ?? "sub_users-users",
    path: indexZuAMsz8gsKMeta?.path ?? "/sub_users/users",
    meta: indexZuAMsz8gsKMeta || {},
    alias: indexZuAMsz8gsKMeta?.alias || [],
    redirect: indexZuAMsz8gsKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexoTp3EyoX78Meta?.name ?? "terms_and_conditions",
    path: indexoTp3EyoX78Meta?.path ?? "/terms_and_conditions",
    meta: indexoTp3EyoX78Meta || {},
    alias: indexoTp3EyoX78Meta?.alias || [],
    redirect: indexoTp3EyoX78Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexOjyJcTlSBbMeta?.name ?? "users-password-edit",
    path: indexOjyJcTlSBbMeta?.path ?? "/users/password/edit",
    meta: indexOjyJcTlSBbMeta || {},
    alias: indexOjyJcTlSBbMeta?.alias || [],
    redirect: indexOjyJcTlSBbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index9mtajvokECMeta?.name ?? "users-password-new",
    path: index9mtajvokECMeta?.path ?? "/users/password/new",
    meta: index9mtajvokECMeta || {},
    alias: index9mtajvokECMeta?.alias || [],
    redirect: index9mtajvokECMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexrs6lyVtSefMeta?.name ?? "users-sign_in",
    path: indexrs6lyVtSefMeta?.path ?? "/users/sign_in",
    meta: indexrs6lyVtSefMeta || {},
    alias: indexrs6lyVtSefMeta?.alias || [],
    redirect: indexrs6lyVtSefMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1735812243/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]