import { z } from "zod"
import { compose, empty, omit, pick, tap, transformers } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"
import { normalizeKey } from "../assets/js/utilities.js"

const { Integer, Decimal } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        account_id: { type: Integer, default: 1 },
        caller_id: { type: String },
        provision_type: { type: String, default: "client_provided" },
        status: { type: Integer },
        authorization_letter: { type: File, required: true },
        is_disabled: { type: Boolean, default: true },
        created_at: { type: Date, form: false },
        updated_at: { type: Date, form: false },
}

const filters = [
        { key: "account", group: "select_model", option_key: "accounts" },
        { key: "caller_id", group: "text" },
        { key: "provision_type", group: "select_fixed", option_key: "provision_types", option_type: Number },
        { key: "status", group: "select_fixed", option_key: "statuses", option_type: Number },
        { key: "created_at", group: "date" },
        { key: "updated_at", group: "date" },
]

const title = {
        create: "Create Caller",
}

export const Request = ({ helpers, config }) => ({
        Create: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: ["id", "created_at"],
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form })
                        .transform(transformers.object.toFormData)
                        .safeParse(data)
        },
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                ...Shared.Request.Scoped(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: ["id", "created_at"],
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form })
                        .transform(transformers.object.toFormData)
                        .safeParse(data)
        }
})

export const Response = ({ helpers, config }) => ({
        FormOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                provision_types: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        Create: z.object({
                id: z.number(),
        }).safeParse,
        FilterOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                provision_types: z.tuple([z.string("name"), z.number("id")]).array(),
                statuses: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        is_disabled: z.boolean(),
                        created_at: z.coerce.date({ offset: true }),
                        status: z.string(),
                        account: z.string(),
                        number: z.string(),
                        provision_type: z.string(),
                        decided: z.boolean(),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Count: z.object({
                glabs_provided_caller_ids: z.number(),
                client_provided_caller_ids: z.number(),
                for_approval: z.number(),
                pending: z.number(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({
                        attributes,
                        exclude: ["authorization_letter"],
                }),
                account: z.string(),
                status_name: z.string(),
                provision_type_human: z.string(),
                authorization_letter_url: z.string(),
                decided: z.boolean(),
        }).safeParse,
})

const transformer = ([attribute, value]) => {
        const result = {}
        switch (attribute) {
                case "account_id": {
                        result.title = "Account"
                        result.options = []
                        result.option_key = "accounts"
                } break
                case "caller_id": {
                        result.placeholder = "09XXXXXXXXX"
                } break
                case "provision_type": {
                        result.options = []
                        result.option_key = "provision_types"
                        result.option_format = ([key, _value]) => ([normalizeKey(key), key])
                } break
                case "status": {
                        result.options = []
                        result.option_key = "statuses"
                } break
        }
        return result
}

export const Form = ({ helpers, config }) => ({
        Create: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
})

export const Api = ({ helpers, config }) => ({
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        Create: {
                bodyParser: (options = { form: {} }) => config.Request.Create({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
                successParser: config.Response.Create,
        },
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Count: {
                successParser: config.Response.Count,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form
                }),
                errorParser: Shared.Response.Errors,
        },
        Approve: empty(Object),
        Disapprove: empty(Object),
})

// TODO(aes): make dynamic based on server
export const Scopes = () => Shared.Scopes([
        "glabs_provided_caller_ids",
        "client_provided_caller_ids",
        "for_approval",
        "pending",
])

export const TableHeaders = () => Shared.TableHeaders([
        "id",
        "status",
        "account",
        "number",
        "provision_type",
        "is_disabled",
        { key: "created_at", format: formatters.date }
])

export const Pages = ({ helpers, config }) => ({
        list: {
                breadcrumb: {
                        actions: [{ name: "New Caller", path: "new" }]
                },
                actions: [
                        {
                                name: "Approve",
                                handle: "approve",
                                enabled: (resource) => !resource.decided,
                        },
                        {
                                name: "Disapprove",
                                handle: "disapprove",
                                enabled: (resource) => !resource.decided,
                        },
                ],
        },
        show: {
                actions: [
                        { name: "Edit Caller", path: "edit" },
                        {
                                name: "Approve",
                                operation: {
                                        name: "approve",
                                        enabled: (resource) => !resource.decided,
                                },
                        },
                        {
                                name: "Disapprove",
                                operation: {
                                        name: "disapprove",
                                        enabled: (resource) => !resource.decided,
                                },
                        },
                ],
                groups: [
                        {
                                name: (resource) => `Caller ID #${resource?.id}`,
                                type: "property",
                                value: (resource) => {
                                        const result = {}

                                        result.id = resource.id
                                        result.account = { link: `/admin/accounts/${resource.account_id}`, value: resource.account }
                                        result.caller = resource.caller_id
                                        result.provision_type = resource.provision_type_human
                                        result.status = resource.status_name
                                        result.is_disabled = resource.is_disabled
                                        result.authorization_letter = {
                                                api: true,
                                                link: resource.authorization_letter_url,
                                                value: "Download",
                                        }
                                        result.created_at = resource.created_at
                                        result.updated_at = resource.updated_at

                                        return result
                                },
                        },
                ],
        },
})

export default Shared.defineAdminModel({
        key: "caller_id",
        memberName: "Caller ID",
        collectionName: "Caller IDs",
        title,
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        Scopes,
        TableHeaders,
        Pages,
})
