/**
 * source: app/assets/javascripts/plugins/audit_trail.js
 */

import axios from "axios"

function AuditTrailListTranslatorFactory() {
        function toListFromRepresentation(listInRepresentation, listClass) {
                return new listClass(
                        listInRepresentation.data,
                        listInRepresentation.total_items,
                        listInRepresentation.per_page,
                        listInRepresentation.from,
                        listInRepresentation.to,
                        listInRepresentation.current_page,
                        listInRepresentation.page_total
                )
        }

        this.toListFromRepresentation = toListFromRepresentation
}
const AuditTrailListTranslator = new AuditTrailListTranslatorFactory()

function AuditTrailListAdaptorFactory(httpInstance) {
        async function toList({ page, size, search, date }, auditTrailListClass) {
                let auditTrailList = null
                try {
                        const response = await httpInstance.get('/audit_logs.json', {
                                params: {
                                        page,
                                        per: size,
                                        sorted_by: 'activity_date_desc',
                                        search_with: search,
                                        between_by_activity_date: date
                                }
                        })
                        return AuditTrailListTranslator.toListFromRepresentation(response.data, auditTrailListClass)
                } catch (error) {
                        return Promise.reject(error)
                }
        }

        this.toList = toList
}

function AuditTrailService(AuditTrailListAdaptor, /* TODO(aes): "origin" no longer needed? */ origin) {
        this.auditTrailList = async function auditTrailList({ page, size, search, date }, auditTrailListClass) {
                return AuditTrailListAdaptor.toList({ page, size, search, date }, auditTrailListClass)
        }

        this.auditTrailDownloadLink = function auditTrailDownloadLink({ search, date, baseUrl }) {
                const url = new URL('/audit_logs/download.csv', baseUrl)
                url.searchParams.set('search_with', search)
                url.searchParams.set('between_by_activity_date', date)
                return url.href
        }

        this.toModel = function toModel(item) {
                const {
                        id,
                        user_name,
                        user_email,
                        action,
                        information,
                        ip_address,
                        activity_date
                } = item

                return {
                        id,
                        user: user_name,
                        email: user_email,
                        action,
                        information,
                        ipAddress: ip_address,
                        dateTime: activity_date
                }
        }
}

export default defineNuxtPlugin((nuxtApp) => {
        const http = nuxtApp.$http
        const AuditTrailListAdaptor = new AuditTrailListAdaptorFactory(http)
        return {
                provide: {
                        auditTrailService: new AuditTrailService(AuditTrailListAdaptor, window.location.origin),
                }
        }
})
