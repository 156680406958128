import { z } from "zod"
import { empty, pick, omit } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal, Encrypted } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        email: { type: String, required: true },
        first_name: { type: String, required: true },
        last_name: { type: String, required: true },
        password: { type: Encrypted, required: true },
        password_confirmation: { type: Encrypted },
        last_sign_in_at: { type: Date, nullable: true, form: false },
        created_at: { type: Date, form: false },
        updated_at: { type: Date, form: false },
}

const filters = [
        { key: "email", group: "text" },
        { key: "first_name", group: "text" },
        { key: "last_name", group: "text" },
        { key: "created_at", group: "date" },
]

const Request = ({ helpers, config }) => ({
        Create: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form }).safeParse(data)
        },
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm(pick(["key", "attributes"], config))
                }

                const form = z.object(helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes: config.attributes,
                }))
                return z.object({ [config.key]: form }).safeParse(data)
        },
})

const Response = ({ helpers, config }) => ({
        Create: z.object({
                id: z.number(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        email: z.string(),
                        first_name: z.string(),
                        last_name: z.string(),
                        current_sign_in_at: z.coerce.date({ offset: true }),
                        sign_in_count: z.number(),
                        created_at: z.coerce.date({ offset: true }),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Show: z.object({
                ...helpers.modelToParser({
                        exclude: ["password", "password_confirmation"],
                        attributes,
                })
        }).safeParse,
})

const Form = ({ helpers, config }) => ({
        Create: helpers.generateFormFields({
                attributes: config.attributes,
        }),
        Update: helpers.generateFormFields({
                attributes: config.attributes,
        }),
})

const Api = ({ helpers, config }) => ({
        Create: {
                bodyParser: (options = { form: {} }) => config.Request.Create({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
                successParser: config.Response.Create,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
})

const TableHeaders = [
        { title: "Id", key: "id" },
        { title: "Email", key: "email" },
        { title: "First Name", key: "first_name" },
        { title: "Last Name", key: "last_name" },
        { key: "current_sign_in_at", format: formatters.date },
        { title: "Sign In Count", key: "sign_in_count" },
        { key: "created_at", format: formatters.date },
]

const Pages = {
        show: {
                actions: [
                        { name: "Edit Admin User", path: "edit" },
                ],
                groups: [
                        {
                                name: "Admin User Details",
                                type: "property",
                                value: (resource) => pick([
                                        "first_name",
                                        "last_name",
                                        "email",
                                        "last_sign_in_at",
                                        "created_at",
                                        "updated_at",
                                ], resource),
                        },
                ],
        },
}

export default Shared.defineAdminModel({
        key: "admin_user",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        TableHeaders,
        Pages,
})
