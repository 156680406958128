import { assign, functionalize, identity, pick, reverse } from "@schtappe/utils"

import { addOptions } from "~/helpers/admin"
import { normalizeKey } from "~/assets/js/utilities"

import AccountRequestedPlan from "~/models/account_requested_plan"
import Account from "~/models/account"
import CustomEmail from "~/models/custom_email"
import SmsMasking from "~/models/sms_masking"
import CallerId from "~/models/caller_id"

export const useParam = (param) => useRoute().params[param]
export const useAction = (action) => {
        let result = useRoute().path

        if (result.endsWith("/")) {
                result = result.slice(0, result.length - 1)
        }

        if (action) {
                result = `${result}/${action}`
        }

        return result
}

// TODO(aes): optimize this, stop abusing arrays
export const usePath = ({ offset, append } = {}) => {
        const currentPath = useRoute().path.replace(/\/+$/, "")

        let parts = currentPath.split("/")

        offset ||= 0
        if (offset != 0) {
                parts = parts.slice(0, parts.length + offset)
        }

        append && parts.push(...[].concat(append))

        return parts.join("/")
}

export const useResourcePath = ({ model, id }) => usePath({ append: id })

export const useResourceEditPath = ({ model, id }) => usePath({ append: [id, "edit"] })

// TODO(aes): deduplicate this
const HANDLERS = new Map([
        [AccountRequestedPlan, {
                approve: (params = {}) => {
                        if (!confirm("Are you sure you want to approve this request?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[AccountRequestedPlan.key].approve({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
                disapprove: (params = {}) => {
                        if (!confirm("Are you sure you want to disapprove this request?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[AccountRequestedPlan.key].disapprove({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
        }],
        [CallerId, {
                approve: (params = {}) => {
                        if (!confirm("Are you sure you want to approve this request?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[CallerId.key].approve({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
                disapprove: (params = {}) => {
                        if (!confirm("Are you sure you want to disapprove this request?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[CallerId.key].disapprove({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
        }],
        [CustomEmail, {
                delete: (params = {}) => {
                        if (!confirm("Are you sure you want to delete this?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[CustomEmail.key].delete({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
        }],
        [SmsMasking, {
                approve: (params = {}) => {
                        if (!confirm("Are you sure you want to approve this request?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[SmsMasking.key].approve({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
                disapprove: (params = {}) => {
                        if (!confirm("Are you sure you want to disapprove this request?")) return
                        const { $api } = useNuxtApp()
                        $api.Admin[SmsMasking.key].disapprove({
                                onError(error) {
                                        // TODO(aes)
                                        console.error(error)
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
                test_sms: (params = {}) => {
                        const { $api } = useNuxtApp()
                        $api.Admin[SmsMasking.key].test_sms({
                                onError(error) {
                                        if (error.type === "ERROR_RESPONSE") {
                                                alert(error.payload)
                                        } else {
                                                // TODO(aes)
                                                console.error(error)
                                        }
                                },
                                onSuccess() {
                                        throw new Error("Implement handler success")
                                },
                                ...params,
                        })
                },
        }],
])

export const useHandle = (key, action) => HANDLERS.get(key)[action]

// TODO(aes): should this be moved to pinia?
export const useAdminAccountForm = () => useState("admin_account_form", () => Account.Request.Wizard(Symbol.for("empty")).signup_form)
export const useAdminAccountUpdateForm = () => useState("admin_account_update_form", () => Account.Request.Update(Symbol.for("empty")).account)
export const useAccount = ({ account_id, onError }) => {
        const result = ref({})
        const fetch = () => {
                const { $api } = useNuxtApp()
                return $api.Admin.Account.show({
                        account_id,
                        onError,
                        onSuccess(data) {
                                result.value = data
                        },
                })
        }
        return [result, fetch]
}

const getItemFromMap = (map) => (key) => {
        let result
        if (map.has(key)) {
                result = map.get(key)
        } else {
                result = ref({})
                map.set(key, result)
        }
        return result
}

const fetchDataResource = (getter, verb) => ({ model, ...options }) => {
        const data = getter(model)
        const fetch = (params = {}) => {
                const { $api } = useNuxtApp()
                return $api.Admin[model.key][verb]({
                        ...options,
                        onSuccess(response) {
                                data.value = response
                        },
                        ...params,
                })
        }
        return [data, fetch]
}

const getConfiguredParams = (keys) => pick(keys, useRoute().params)

const Resources = new Map()
export const useResource = getItemFromMap(Resources)
export const useFetchResource = (options) => {
        const [data, fetch] = fetchDataResource(useResource, "show")(options)

        const fetchItem = (params) => {
                fetch({
                        ...getConfiguredParams(options.params),
                        ...params,
                })
        }

        return [data, fetchItem]
}

const getScope = ({ model, ...options }) => {
        if (!model.Scopes().length) {
                return { success: false }
        }

        const scopes = ref(model.Scopes())
        const scope = ref(undefined)
        const setScope = (value) => {
                scope.value = value
        }
        const fetchCount = (params) => {
                const { $api } = useNuxtApp()
                return $api.Admin[model.key].count({
                        ...options,
                        onSuccess(response) {
                                scopes.value = scopes.value.map((item) => {
                                        return { ...item, count: response[item.key] }
                                })
                        },
                        ...params,
                })
        }
        onMounted(() => {
                setScope(useRoute().query.scope)
                watch(scope, () => {
                        // NOTE(aes): undefined will remove partial query strings
                        useRouter().push({ query: { scope: scope.value || undefined } })
                })
        })
        return { success: true, data: [scope, scopes, setScope, fetchCount] }
}

// TODO(aes): this should be centralized somehow, possibly a hashmap
const createFilterEntry = (filter) => {
        const { key, group, option_key, option_type } = filter
        const name = normalizeKey(key)
        let filterType
        let options
        let additionalValue

        switch (group) {
                case "select_model": {
                        filterType = "select"
                        options = []
                } break
                case "select_fixed": {
                        filterType = "select"
                        options = []
                } break
                case "select_boolean": {
                        filterType = "select"
                        options = [["Yes", true], ["No", false]]
                } break
                case "date": {
                        filterType = "date"
                } break
                case "number": {
                        filterType = "number"
                        additionalValue = [`${key}_type`, "equals"]
                } break
                case "text": {
                        filterType = "text"
                        additionalValue = [`${key}_type`, "contains"]
                } break
                default: {
                        throw new Error("Invalid filter group")
                }
        }

        return {
                name,
                type: filterType,
                key,
                option_key,
                options,
                additionalValue,
        }
}

const Collections = new Map()
export const useCollections = getItemFromMap(Collections)
export const useFetchCollection = (options = {}) => {
        const { model } = options
        const headers = readonly(model.TableHeaders())

        const [response, fetchData] = fetchDataResource(useCollections, "list")(options)

        const items = computed(() => response.value.data)
        const pagination = computed(() => pick(["current_page", "page_total", "per_page", "total_items"], response.value))
        const currentPage = ref(1)
        const setPage = (page) => currentPage.value = page

        let handleMounted
        let fetchItems

        const filters = ref(!model.filters ? [] : model.filters.map(createFilterEntry))
        const fetchFilterOptions = () => {
                const { $api } = useNuxtApp()
                if (!$api.Admin[model.key].filter_options) return

                $api.Admin[model.key].filter_options({
                        onSuccess(data) {
                                filters.value.forEach((filter) => {
                                        if (!filter.option_key) return
                                        filter.options = data[filter.option_key].map(filter.option_format || identity)
                                })
                        },
                        onError(error) {
                                // TODO(aes)
                                console.error(error)
                        },
                })
        }
        const setFilters = (selected) => {
                fetchItems({ q: { ...selected } })
        }

        let result = {
                headers,
                items,
                pagination,
                currentPage,
                setPage,
                filters,
                setFilters,
        }

        const _scope = getScope(options)
        if (_scope.success) {
                const [scope, scopes, setScope, _fetchCount] = _scope.data
                const fetchCount = (params = {}) => {
                        return _fetchCount({
                                ...getConfiguredParams(options.params),
                                ...params,
                        })
                }

                fetchItems = (params = {}) => fetchData({
                        ...getConfiguredParams(options.params),
                        page: currentPage.value,
                        scope: scope.value,
                        ...params,
                })

                handleMounted = () => {
                        fetchItems()
                        fetchCount()
                        watch(scope, () => fetchItems())
                        fetchFilterOptions()
                }

                assign(result, { fetchItems, scope, scopes, setScope, fetchCount })
        } else {
                fetchItems = (params = {}) => fetchData({
                        ...getConfiguredParams(options.params),
                        page: currentPage.value,
                        ...params,
                })

                handleMounted = () => {
                        fetchItems()
                        fetchFilterOptions()
                }

                assign(result, { fetchItems })
        }

        onMounted(handleMounted)
        watch(currentPage, () => {
                fetchItems()
        })

        return result
}

export const useFetchModel = ({ collection, ...options } = {}) => {
        if (collection) {
                return useFetchCollection(options)
        } else {
                return useFetchResource(options)
        }
}

export const useFormResource = (options = {}) => {
        const { model, action, ...overrides } = options

        let form = null
        let fields = null
        let handleSubmit = null
        let handleFiles = null
        const errors = ref({})
        const previous = usePath({ offset: -1 })
        switch (action) {
                case "create": {
                        form = ref(model.Request.Create(Symbol.for("empty"))[model.key])
                        fields = ref(model.Form.Create())
                        handleSubmit = (_event) => {
                                const { $api } = useNuxtApp()
                                return $api.Admin[model.key].create({
                                        form: form.value,
                                        onError(error) {
                                                if (error.type === "ERROR_RESPONSE") {
                                                        errors.value = error.payload
                                                } else {
                                                        // TODO(aes)
                                                        console.error(error)
                                                }
                                        },
                                        onSuccess(data) {
                                                useRouter().push(usePath({ offset: -1, append: data.id }))
                                        },
                                        ...overrides,
                                        ...getConfiguredParams(options.params),
                                })
                        }
                        handleFiles = ({ attribute, files }) => {
                                form.value[attribute] = files[0]
                        }
                } break
                case "edit": {
                        form = ref(model.Request.Update(Symbol.for("empty"))[model.key])
                        fields = ref(model.Form.Update())
                        handleSubmit = (_event) => {
                                const { $api } = useNuxtApp()
                                return $api.Admin[model.key].update({
                                        form: form.value,
                                        onError(error) {
                                                if (error.type === "ERROR_RESPONSE") {
                                                        errors.value = error.payload
                                                } else {
                                                        // TODO(aes)
                                                        console.error(error)
                                                }
                                        },
                                        onSuccess(_data) {
                                                useRouter().push(usePath({ offset: -1 }))
                                        },
                                        ...overrides,
                                        ...getConfiguredParams(options.params),
                                })
                        }
                        handleFiles = ({ attribute, files }) => {
                                form.value[attribute] = files[0]
                        }

                        watch(useResource(model), (resource) => {
                                if (model.resourceToForm) {
                                        Object.assign(form.value, model.resourceToForm(resource))
                                } else {
                                        Object.assign(form.value, resource)
                                }

                                Object.entries(resource).forEach(([key, value]) => {
                                        if (!value) return
                                        if (!key.endsWith("_url")) return
                                        const attribute = key.split("_url")[0]
                                        const baseUrl = useRuntimeConfig().public.baseUrl
                                        const url = `${baseUrl}/${value}`
                                        useAuthorizedFetch(url, {
                                                onResponse({ response }) {
                                                        const content = [response._data]
                                                        const filename = value.split("/").slice(-1)[0] || ""
                                                        form.value[attribute] = new File(content, filename)
                                                }
                                        })
                                })
                        })
                } break
                default: { throw new Error("Invalid form action for resource") }
        }

        const handleModelIds = () => {
                const params = options.params || []
                Object.keys(form.value).forEach((attribute) => {
                        if (!options.params.includes(attribute)) return
                        form.value[attribute] = Number(useParam(attribute))
                })
        }

        const handleFormOptions = () => {
                if (!fields) return

                const { $api } = useNuxtApp()
                if (!$api.Admin[model.key].form_options) return

                $api.Admin[model.key].form_options({
                        onSuccess(data) {
                                fields.value.forEach((field) => {
                                        if (!field.options) return
                                        // TODO(aes): remove this and replace with
                                        // field.options = data[field.option_key].map(field.option_format || identity)
                                        const options = data[field.option_key].map(field.option_format || identity)
                                        addOptions(fields.value, field.attribute, options)
                                })
                        },
                        onError(error) {
                                // TODO(aes)
                                console.error(error)
                        },
                })
        }

        onMounted(handleModelIds)
        onMounted(handleFormOptions)

        return [fields, form, handleSubmit, errors, previous, handleFiles]
}

// TODO(aes): optimize this, stop abusing arrays
// () -> [{ name, link, actions }]
export const useBreadcrumbs = () => computed(() => {
        const current = useRoute()
        return current.matched
                .filter((route) => route.meta.breadcrumb)
                .map((route) => {
                        const breadcrumb = typeof route.meta.breadcrumb == "function"
                                ? route.meta.breadcrumb
                                : () => route.meta.breadcrumb

                        const { title, actions, model, resource: isResource } = breadcrumb(current.params)

                        let name
                        let resource
                        let fetchResource
                        if (isResource) {
                                [resource, fetchResource] = useFetchResource({ model, params: Object.keys(current.params) })
                                fetchResource({
                                        onError(error) {
                                                // TODO(aes)
                                                console.error(error)
                                        },
                                })
                                name = functionalize(title)({ resource })
                        } else {
                                name = functionalize(title)()
                        }
                        name = unref(name)

                        const link = Object.keys(current.params).reduce((result, key) => {
                                const value = current.params[key]
                                return result.replace(`:${key}()`, value)
                        }, route.path)

                        const result = { name, link, actions, resource }
                        return result
                })
})
