import { z } from "zod"
import { defu } from "defu"

import { capitalize, functionalize } from "@schtappe/utils"

import * as Definitions from "./definitions.js"
const { Integer, Decimal, INPUT_MAP } = Definitions

export const Request = {
        Paginated: () => ({
                page: z.number().optional(),
        }),
        Scoped: (/* TODO(aes): restrict type; use z.enum */) => ({
                scope: z.string().optional(),
        }),
}

export const Response = {
        Errors: z.object({
                errors: z.record(z.string(), z.string().array()),
        }).safeParse,
        Paginated: () => ({
                current_page: z.number(),
                page_total: z.number(),
                per_page: z.number(),
                total_items: z.number(),
        }),
        Timestamps: (props = ["created_at"]) => props.reduce((result, item) => {
                result[item] = z.coerce.date()
                return result
        }, {}),
}

export const Scopes = (scopes) =>
        scopes.map((scope) => ({ key: scope, count: 0 }))

export const TableHeaders = (headers) =>
        headers.map((header) => {
                if (typeof header == "string") {
                        return { key: header }
                }
                return header
        })

const helpers = {
        createEmptyForm({ key, attributes, append }) {
                const form = Object.keys(attributes).reduce((result, attribute) => {
                        if (attribute == "id" || attribute == "created_at") return result
                        const { type, default: defaultValue } = attributes[attribute]
                        result[attribute] = defaultValue || INPUT_MAP.get(type).default()
                        return result
                }, {})
                return { [key]: form, ...append }
        },
        modelToParser({ exclude, attributes } = {}) {
                return Object.entries(attributes).reduce((result, [key, value]) => {
                        if (exclude?.includes(key)) return result
                        const { type, nullable } = value
                        result[key] = INPUT_MAP.get(type).parser()
                        if (nullable) {
                                result[key] = result[key].nullable()
                        }
                        return result
                }, {})
        },
        generateFormFields(options) {
                const { initial, attributes, transformer } = options
                console.assert(attributes)

                return () => Object.entries(attributes).reduce((result, [attribute, value]) => {
                        const { type, required, form, disabled, encrypted } = value
                        if (form === false) return result

                        const field = {
                                attribute,
                                required,
                                disabled,
                                type: INPUT_MAP.get(type).input,
                                step: type === Decimal ? "0.1" : "0",
                                ...functionalize(transformer)([attribute, value]),
                        }
                        result.push(field)
                        return result
                }, initial ? [...initial] : [])
        },
        // TODO(aes): implement flattening of object in the plugins/api.js, not here
        // TODO(aes): this should be centralized somehow, possibly a hashmap
        toQueryObject({ query, filters }) {
                return filters.reduce((result, filter) => {
                        const { key, group } = filter
                        if (!(key in query)) return result

                        switch (group) {
                                case "select_model": {
                                        result[`q[${key}_id_eq]`] = query[key]
                                } break
                                case "select_fixed": {
                                        result[`q[${key}_eq]`] = query[key]
                                } break
                                case "select_boolean": {
                                        result[`q[${key}_eq]`] = query[key]
                                } break
                                case "date": {
                                        if (query[`${key}`] && query[key].length > 0) {
                                                result[`q[${key}_gteq_datetime]`] = query[`${key}`][0].toISOString().split('T')[0]
                                                result[`q[${key}_lteq_datetime]`] = query[`${key}`][1].toISOString().split('T')[0]
                                        }
                                } break
                                case "number": {
                                        const number_type = query[`${key}_type`]
                                        result[`q[${key}_${number_type}]`] = query[key]
                                } break
                                case "text": {
                                        const text_type = query[`${key}_type`]
                                        result[`q[${key}_${text_type}]`] = query[key]
                                } break
                                default: {
                                        throw new Error("Invalid filter group")
                                }
                        }
                        return result
                }, {})
        },
        // TODO(aes): this should be centralized somehow, possibly a hashmap
        createFiltersParser({ filters }) {
                return filters.reduce((result, filter) => {
                        const { key, group, valType } = filter

                        switch (group) {
                                case "select_model": {
                                        result[key] = z.number()
                                } break
                                case "select_fixed": {
                                        result[key] = valType == String ? z.string() : z.number()
                                } break
                                case "select_boolean": {
                                        result[key] = z.boolean()
                                } break
                                case "date": {
                                        result[key] = z.tuple([z.date("from"), z.date("to")])
                                } break
                                case "number": {
                                        result[key] = z.number()
                                        result[`${key}_type`] = z.enum(["equals", "greater_than", "less_than"]).optional()
                                } break
                                case "text": {
                                        result[key] = z.string()
                                        result[`${key}_type`] = z.enum(["contains", "equals", "starts_with", "ends_with"]).optional()
                                } break
                                default: {
                                        throw new Error("Invalid filter group")
                                }
                        }
                        result[key] = result[key].optional()

                        return result
                }, {})
        },
}

export const defineAdminModel = (config = {}) => {
        const {
                key, collectionKey: _collectionKey, title: _title,
                attributes, filters,
                Request: _Request, Response: _Response, Errors: _Errors, Api: _Api,
                Pages: _Pages, TableHeaders: _TableHeaders, Scopes: _Scopes, Form: _Form,
                ...rest
        } = config
        console.assert(key)

        const result = {}

        const collectionKey = _collectionKey
                ? (functionalize(_collectionKey)())
                : `${key}s`

        Object.assign(result, { attributes, filters })

        const Request = functionalize(_Request)({ helpers, config: result })
        Object.assign(result, { Request })

        const Response = functionalize(_Response)({ helpers, config: result })
        Object.assign(result, { Response })

        const Errors = functionalize(_Errors)({ helpers, config: result })
        Object.assign(result, { Errors })

        const Api = functionalize(_Api)({ helpers, config: result })
        Object.assign(result, { Api })

        let _memberNameResult = ""
        const _memberName = (key) => _memberNameResult ||= rest.memberName || key.split("_").map(capitalize).join(" ")
        const Pages = defu(functionalize(_Pages)({ helpers, config: result }), {
                new: {
                        title: `New ${_memberName(key)}`,
                        action: `Create ${_memberName(key)}`,
                        path: "new",
                },
                list: {
                        isViewEnabled: true,
                        isEditEnabled: true,
                        actions: [],
                },
                show: {
                        title: ({ resource }) => `${_memberName(key)} #${resource.id}`,
                        actions: [],
                },
                edit: {
                        title: `Edit ${_memberName(key)}`,
                }
        })
        Object.assign(result, {
                Pages,
                get newTitle() {
                        return Pages.new.title
                },
                get newPath() {
                        return Pages.new.path
                },
                get editTitle() {
                        return Pages.edit.title
                },
        })

        // TODO(aes): migrate "title" to Pages
        // let _memberNameResult = ""
        // const _memberName = (key) => _memberNameResult ||= key.split("_").map(capitalize).join(" ")
        const title = {
                new: `New ${_memberName(key)}`,
                edit: `Edit ${_memberName(key)}`,
                create: `Create ${_memberName(key)}`,
                ...(_title || {})
        }

        const TableHeaders = _TableHeaders
                ? functionalize(_TableHeaders)
                : (() => [])

        const Scopes = _Scopes || (() => [])

        const Form = functionalize(_Form || {})({ helpers, config: result })

        Object.assign(result, {
                key,
                collectionKey,
                title,
                get memberName() {
                        return rest.memberName || _memberName(key)
                },
                get collectionName() {
                        return rest.collectionName || key.split("_").map(capitalize).join(" ") + "s"
                },
                get listActions() {
                        return Pages.list?.breadcrumb?.actions || [{
                                name: result.newTitle,
                                path: result.newPath,
                        }]
                },
                TableHeaders,
                Scopes,
                Form,
                ...rest,
        })

        return result
}
