/**
 * NOTE(aes): https://dev.to/israelortuno/event-bus-pattern-in-nuxt-3-with-full-typescript-support-1okp
 */

import mitt from 'mitt'

export default defineNuxtPlugin(() => {
        const emitter = mitt()

        // source: app/assets/javascripts/event_bus.js
        if (typeof window !== 'undefined') {
                window.eventBus = {
                        $emit: emitter.emit,
                        $on: emitter.on,
                        $off: emitter.off,
                }
        }

        return {
                provide: {
                        emit: emitter.emit, // Will emit an event
                        on: emitter.on, // Will register a listener for an event
                        off: emitter.off,
                }
        }
})
