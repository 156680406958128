import { z } from "zod"

export const Request = {
        Inquiries: z.object({
                "g-recaptcha-response": z.string(),
                inquiry: z.object({
                        first_name: z.string(),
                        last_name: z.string(),
                        mobile_number: z.string(),
                        organization: z.string(),
                        email_address: z.string(),
                        content: z.string(),
                })
        }).safeParse,
        Experience: z.object({
                mobile_number: z.string(),
        }).safeParse,
}

export const Response = {
        Experience: z.object({
                notice: z.string(),
        }).safeParse,
}

export const Error = {
        Experience: z.object({
                error: z.string(),
        }).safeParse,
}
